import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../lib/ui/tiden";
import sbo from './sbo.js'

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Kitchens extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};

      this.state.mga = [
          {gid: '001', id: 'burgers', text: 'BURGERS', img: 'burgers.png', txtimg: 'txtburger.jpeg', class: 'mg-burger', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
          {gid: '002', id: 'pizza', text: 'PIZZA', img: 'pizza.png', txtimg: 'txtpizza.png', class: 'mg-pizza', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
          {gid: '003', id: 'pasta', text: 'PASTA', img: 'pasta.png', txtimg: 'txtpasta.png', class: 'mg-pasta', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' },
          {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sushi.png', txtimg: 'txtsushi.png', class: 'mg-sushi', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
          {gid: '005', id: 'taco', text: 'TACO', img: 'taco.png', txtimg: 'txttaco.png', class: 'mg-taco', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
          {gid: '006', id: 'kebab', text: 'KEBAB', img: 'kebab.png', txtimg: 'txtkebab.png', class: 'mg-kebab', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' },
          {gid: '007', id: 'wok', text: 'WOK', img: 'wok.png', txtimg: 'txtwok.png', class: 'mg-wok', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
          {gid: '008', id: 'sallad', text: 'SALLAD', img: 'leangreen.png', txtimg: 'txtleangreen.png', class: 'mg-sallad', desc: 'Njut av våra fräscha sallader.' },
          {gid: '009', id: 'husman', text: 'HUSMAN', img: 'kebab.png', txtimg: 'txthusman.png', class: 'mg-husman', desc: 'Husmanskost' }
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.srs_init();
  }

    mg_cls(btn) {
        var cls = "flex col";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        css.margin = "5px 12px";
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      var url = "";
      url = "http://filpub.svepos.se/img/foodle/";
      url = url + fil;
      return url;
    }

    mg_open(mgo) {
        sbo.cdo.gid = mgo.gid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/foodle/menu/grp";
        this.props.history.push(url);
    };

  render() {

    return (
    <div className="web-app">
        <div> Foodle Våra Kök </div>

        <div className="mgv">
            <div className="mgv-grid">
                { this.state.mga.map((mgo, key) =>
                    <div className={ this.mg_cls(mgo) } key={key}
                         onClick={() => this.mg_open(mgo) }
                         style={ this.mg_style(mgo) }
                    >
                        <div className="flex-col">
                            <img src={this.imgUrl(mgo.img)} className={mgo.class} alt="Foodle" />
                            <img src={this.imgUrl(mgo.txtimg)} className="foodle-txt px-5" alt="Foodle" />
                        </div>
                        <div className="flex-row px-5 py-3" >
                            <div>{ mgo.desc }</div>
                        </div>
                        <div className="flex-row px-5 py-3" >
                            <div className="flex"></div>
                            <div className="mg-btn" onClick={() => this.mg_open(mgo) }>TILL KÖK</div>
                            <div className="flex"></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_Kitchens);
