import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../../lib/ui/tiden";


function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_About extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  pg_style() {
    var css = {};
    css.background = "#ff92d4";
    css.color = "#f43b24";
    css.padding = "0px auto";
    return css;
  }
  ctx_style() {
    var css = {};
    css.width = "550px";
    css.padding = "10px 0px";
    return css;
  }

  render() {

    return (
    <div className="web-app">
      <section>
        <div className="page" style={ this.pg_style() }>
          <div className="flex-row flex-center py-5">
            <div style={ this.ctx_style() }>

            <div className="text-center font-bold font-colby text-tomato f80 py-3">
                  STORYN OM FOODLE
            </div>
            <div className="mx-auto">
                  <div className="text-tomato text-center f16">
                    Att äta en god måltid tillsammans med nära och kära kan vara dagens höjdpunkt. Men när det är ont om
                    tid, inspirationen tryter och alla är oense om vad man ska äta kan det vara dagens jobbigaste stund…
                    Att stå i köket och och försöka laga hela familjens favoriträtter är nästan omöjligt. Foodle går
                    helt enkelt ut på att lösa dessa knutar. Vi fokuserar enbart på mat för leverans och således på att
                    göra din ”home delivery”-upplevelse så bra som möjligt. Genom att erbjuda ett brett utbud av olika
                    kök vars rätter levereras med en och samma körning, kan alla runt matbordet bestämma vad var och en
                    vill äta. Eftersom all mat från Foodle både görs och levereras av våra egna anställda har vi full
                    kontroll på hela flödet och, viktigast av allt, ingen annan att skylla på. Alltså, flera egna kök -
                    en leverans - alla får bestämma.
                    <br />
                      Bon appétit! /Foodle
                  </div>
              </div>
          </div>
          </div>
        </div>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240">
                <path fill="#ff92d4" fill-opacity="1" d="M0,128L34.3,112C68.6,96,137,64,206,85.3C274.3,107,343,181,411,208C480,235,549,213,617,181.3C685.7,149,754,107,823,128C891.4,149,960,235,1029,229.3C1097.1,224,1166,128,1234,90.7C1302.9,53,1371,75,1406,85.3L1440,96L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
              </svg>
            </span>
      </section>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_About);
