import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../../lib/ui/tiden";


function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_About extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  pg_style() {
    var css = {};
    css.color = "#fee260";
    css.font = "Colby";
    css.padding = "0px";
    return css;
  }
  ctx_style() {
    var css = {};
    css.width = "450px";
    return css;
  }

  render() {

    return (
    <div className="web-app">
      <section>
        <div className="page foodle-bg" style={ this.pg_style() }>

          <div className="flex-row flex-center py-5">
            <div style={ this.ctx_style() }>

              <div className="text-center font-bold font-colby f80 py-3">
                KONTAKT
              </div>
              <div className="h80"></div>
              <div className="text-center txt_22">
                Hitta hit
              </div>
              <div className="h80"></div>
              <div className="text-center txt_20 text-white">
                Sankt Annegatan 2, 611 34 Nyköping
              </div>
              <div className="h80"></div>
              <div className="text-center txt_22">
                Hjälp och support
              </div>
              <div className="h50"></div>
              <div className="text-center txt_20 text-white">
                Tel: 010 335 01 01
              </div>
              <div className="text-center txt_20 text-white">
                support@foodle.se
              </div>
              <div className="h80"></div>

              <div className="text-center txt_22">
                Foodle AB
              </div>
              <div className="h50"></div>
              <div className="text-center txt_20 text-white">
                Klara Södra kyrkogata 1
              </div>
              <div className="text-center txt_20 text-white">
                111 52 Stockholm
              </div>
              <div className="text-center txt_20 text-white">
                E-post: info@foodle.se
              </div>
              <div className="text-center txt_20 text-white">
                Organisationsnummer: 559292-6348
              </div>
              <div className="h80"></div>

            </div>
          </div>

        </div>
      </section>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_About);
