import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../lib/ui/tiden";
import sbo from "./sbo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as srs from "../../lib/srs";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Home extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.zip = "";
    this.state.adress = "";
    this.state.loco = {};

    this.state.mgs = 1;
    this.state.mga = [];
    this.state.mgx = [
      {gid: '001', id: 'burgers', text: 'BURGERS', img: 'burgers.png', txtimg: 'txtburger.jpeg', class: 'mg-burger', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
      {gid: '002', id: 'pizza', text: 'PIZZA', img: 'pizza.png', txtimg: 'txtpizza.png', class: 'mg-pizza', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
      {gid: '003', id: 'pasta', text: 'PASTA', img: 'pasta.png', txtimg: 'txtpasta.png', class: 'mg-pasta', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' },
      {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sushi.png', txtimg: 'txtsushi.png', class: 'mg-sushi', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
      {gid: '005', id: 'taco', text: 'TACO', img: 'taco.png', txtimg: 'txttaco.png', class: 'mg-taco', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
      {gid: '006', id: 'kebab', text: 'KEBAB', img: 'kebab.png', txtimg: 'txtkebab.png', class: 'mg-kebab', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' },
      {gid: '007', id: 'wok', text: 'WOK', img: 'wok.png', txtimg: 'txtwok.png', class: 'mg-wok', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
      {gid: '008', id: 'sallad', text: 'SALLAD', img: 'leangreen.png', txtimg: 'txtleangreen.png', class: 'mg-sallad', desc: 'Njut av våra fräscha sallader.' },
      {gid: '009', id: 'husman', text: 'HUSMAN', img: 'kebab.png', txtimg: 'txthusman.png', class: 'mg-husman', desc: 'Husmanskost' }
    ];
    this.state.mgo = {};
    this.state.mgo[1] = [
      {gid: '001', id: 'burgers', text: 'BURGERS', img: 'burgers.png', txtimg: 'txtburger.jpeg', class: 'mg-burger', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
      {gid: '002', id: 'pizza', text: 'PIZZA', img: 'pizza.png', txtimg: 'txtpizza.png', class: 'mg-pizza', desc: 'Här har du riktiga klassiska pizzor, frasigt goda.' },
      {gid: '003', id: 'pasta', text: 'PASTA', img: 'pasta.png', txtimg: 'txtpasta.png', class: 'mg-pasta', desc: 'Här finns den godaste pastan i stan. Oxfilepasta är en storsäljare.' }
    ];
    this.state.mgo[2] = [
      {gid: '004', id: 'sushi', text: 'SUSHI', img: 'sushi.png', txtimg: 'txtsushi.png', class: 'mg-sushi', desc: 'Härlig fräsch sushi - både klassisk och nya skolan.' },
      {gid: '005', id: 'taco', text: 'TACO', img: 'taco.png', txtimg: 'txttaco.png', class: 'mg-taco', desc: 'Tacos, nachos, fajitas.. Fredagsmys!' },
      {gid: '006', id: 'kebab', text: 'KEBAB', img: 'kebab.png', txtimg: 'txtkebab.png', class: 'mg-kebab', desc: 'Nyköpings finest! Inget slår en klassisk kebab.' }
    ];
    this.state.mgo[3] = [
      {gid: '007', id: 'wok', text: 'WOK', img: 'wok.png', txtimg: 'txtwok.png', class: 'mg-wok', desc: 'En liten bit av Thailand? Vi tar den till din dörr!' },
      {gid: '008', id: 'sallad', text: 'SALLAD', img: 'leangreen.png', txtimg: 'txtleangreen.png', class: 'mg-sallad', desc: 'Njut av våra fräscha sallader.' },
      {gid: '009', id: 'husman', text: 'HUSMAN', img: 'kebab.png', txtimg: 'txthusman.png', class: 'mg-husman', desc: 'Husmanskost' }
    ];
    this.state.accdata = [
      {
        id: '1',
        title: 'Hur vet jag om ni levererar till min adress?',
        content: `På startsidan anger du till vilken adress ni vill har ordern levererad. Om adressen ni anger
                          ligger utanför vår leveransradie kommer ni då få ett meddelande om att vi inte kan leverera
                          till den angivna adressen. Alternativet då är att välja att ni själva hämtar maten från vår
                          upphämtning adress.`
      },
      {
        id: '2',
        title: 'Kan jag beställa från olika kök och ändå få en leverans?',
        content: `Absolut. Oavsett vad och från vilka kök ni beställer, så kommer allting med en och samma
                          leverans.`
      },
      {
        id: '3',
        title: 'Kan jag beställa till imorgon redan idag?',
        content: `Absolut. När ni avslutar er order kommer ni ha möjligheten att välja bland tillgängliga
                          leveranstider och datum för er ordern.`
      },
      {
        id: '4',
        title: 'Jag är allergisk, kan jag äta er mat?',
        content: `Maten bereds i vårt kök där vi dagligen hanterar flera olika ingredienser. Produkter som lagas
                          delar ofta utrustning för att tillverkas och förvaras. Trots att vi är oerhört noggranna och
                          följer föreskrifter i vårt arbete med att minimera eventuella kontamineringar, så finns det
                          ändå risk att produkter och ingredienser kommer i kontakt med varandra. På
                          produktinformationen framgår det om och vilka allergener som produkten innehåller, men vi kan
                          aldrig garantera att våra produkter är helt fria från någon allergen då spår kan förekomma.`
      },
      {
        id: '5',
        title: 'Hur exakta är era leveranstider?',
        content: `När ni gör en beställning kommer ni att få välja en preliminär leveranstid med 20 minuters
                          intervall. Denna är som sagt en preliminär tid eftersom leveranstiden beror på väldigt många
                          faktorer bortom vår kontroll. Däremot kommer ni få ett meddelande från oss så fort
                          leveransen går från oss och i det meddelandet kommer ni via en länk kunna spåra leveransen i
                          realtid.`
      }
    ];

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
    sbo.cdo.street = this.state.adress;
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
    //this.geo_init();
    //this.get_adress();
    this.dsi_ecom();
  }
  geo_init() {
    if ("geolocation" in navigator) {
      alert("Available");
    } else {
      alert("Not Available");
    }
    navigator.geolocation.getCurrentPosition(function(position) {
      alert(JSON.stringify(position));
      //alert("Latitude is :", position.coords.latitude);
      //alert("Longitude is :", position.coords.longitude);
    }, function(error) {
      alert("Error Code = " + error.code + " - " + error.message);
    });
  }

  get_adress() {
    var self = this;
    var apikey = "AIzaSyD9Ai4X2zbmpvgl_I8VAaSjkj9KFpe0aI8";
    var latlng = this.state.lat + "," + this.state.lon;
    var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlng + "&key=" + apikey;
    alert(JSON.stringify(url));
    fetch(url)
        .then(
            (rsp) => {
              alert(JSON.stringify(rsp));
              //alert("RSP: " + JSON.stringify(rsp));
              var loco = {};
              loco.city = rsp.city;
              loco.zip = rsp.zip;
              //alert(JSON.stringify(loco));
              self.setState({ loco: loco });
            },
            (error) => {
              //alert("Err: " + JSON.stringify(error));
            }
        );
  }
  bg_full_style() {
    var css = {};
    css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    //css.padding = "100px 20px";
    css.width = "100%";
    css.backgroundImage = 'url("http://filpub.svepos.se/img/foodle/foodle_hero_startsida.jpeg")';
    css.backgroundSize = "auto";
    //css.backgroundSize = "cover";
    //css.backgroundAttachment = "fixed";
    css.backgroundPosition = "-550px -100px";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  home_style() {
    var css = {};
    css.margin = "0px";
    //css.padding = "300px 225px";
    css.padding = "100px 25px";
    //css.width = "1024px";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  bg1_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "rgb(207,226,248)";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  bg2_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  bg3_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "#ff92d4";
    css.color = "#f43b24";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  bg4_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "#fff";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }
  unit_zip() {
    var sid = "";
    var zip = this.state.zip;
    //alert(zip);

    if(zip && (zip.substr(0, 2) == "11") ) {
      sid = "S0000281";
    }
    else if(zip && (zip.substr(0, 3) == "611") ) {
      sid = "S0000282";
    }
    else {
      sid = "S0000281";
      //alert("Ej aktivt postnummer");
      //return;
    }
    //sbo.cdo.unit = mgo.id;
    sbo.cdo.zip = zip;
    sbo.cdo.sid = sid;

    sessionStorage.setItem("sbo", JSON.stringify(sbo));

    var url = "";
    //url = url + "/foodle/menu/" + mgo.id;
    url = url + "/foodle/kitchens";
    this.props.history.push(url);
  };
  setAdress(e) {
    //alert(e.currentTarget.value);
    this.setState({ adress: e.currentTarget.value});
  };
  setZip(e) {
    //alert(e.currentTarget.value);
    this.setState({ zip: e.currentTarget.value});
  };

  mg_cls(btn) {
    var cls = "col";
    //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
    //else cls = cls + " btn_free";
    return cls;
  }
  mg_style(btn) {
    var css = {};
    css.margin = "5px 12px";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }

  imgUrl(fil) {
    var url = "";
    url = "http://filpub.svepos.se/img/foodle/";
    url = url + fil;
    return url;
  }

  mg_open(mgo) {
    sbo.cdo.gid = mgo.gid;
    var url = "";
    //url = url + "/foodle/menu/" + mgo.id;
    url = url + "/foodle/menu/grp";
    this.props.history.push(url);
  };
  acch_style(id) {
    var css = {};
    css.color = "#000";
    css.padding = "15px 5px";
    css.borderTop = "1px solid #222";
    if(id == this.state.selid) css.color = "#080";
    return css;
  }
  acc1_style() {
    var css = {};
    //css.borderBottom = "1px solid #222";
    return css;
  }
  acc2_style() {
    var css = {};
    css.borderTop = "1px solid #222";
    return css;
  }
  acc_style(id) {
    var css = {};
    css.padding = "10px 5px";
    if(id != this.state.selid) css.display = "none";
    return css;
  }
  acc_icon_style() {
    var css = {};
    css.color = "#888";
    return css;
  }
  acc_icon(id) {
    var ico = "";
    ico = "angle-down";
    if(id == this.state.selid) {
      ico = "angle-up";
    }
    return ico;
  }
  acc_set(id) {
    if(this.state.selid == id) id = "";
    this.setState({"selid": id});
    return;
  }
  faq() {
    return;
  }
  mgs_add() {
    var mgs = this.state.mgs + 1;
    if(mgs > 3) mgs = 1;
    this.setState({ mgs: mgs });
    return;
  }
  mgs_sub() {
    var mgs = this.state.mgs - 1;
    if(mgs < 1) mgs = 3;
    this.setState({ mgs: mgs });
    return;
  }

  render() {

    return (
        <div className="web-app">

          <section className="bg_home_01" style={ this.bg_full_style() }>

            <div className="wp">
              <div className="h80"></div>
              <div className="flex-col py-5" style={ this.home_style() }>

              <div className="font-bold font-colby text-white f120 py-1">
                <div className="lh120">FLERA KÖK -</div>
                <div className="lh120">EN LEVERANS</div>
              </div>
              <div className="flex-row">
                <div className="txt_14">ANGE ER ADRESS</div>
              </div>
                <div className="flex-row py-2">
                  <input className="w300 txt_22 text-left" value={this.state.adress} onChange={ (e) => this.setAdress(e) }/>
                </div>
                <div className="flex-row py-2 hide">
                  <input className="w200 txt_22 text-center" value={this.state.zip} maxLength="5" onChange={ (e) => this.setZip(e) }/>
                </div>
              <div className="flex-row hide">
                <div className="whome-btn bg-white" value={ this.state.zip } onClick={() => this.unit_zip() }>TILL KÖK</div>
              </div>
              </div>
            </div>

          </section>

          <section className="" style={ this.bg1_style() }>
            <div className="text-center font-bold font-colby text-black f80 py-3">
                    Så FUNKAR det!
            </div>

            <div className="flex-row wpx row">

              <div className="flex-col col-md-4 col-sm-12">
                <div className="text-center py-1">
                  <img className="" src="https://a.storyblok.com/f/100164/157x157/e82eca85f5/foodle_karta_ikon_svar.png" />
                </div>
                <div className="text-center font-bold font-colby fw700 f32 px-1 py-1">
                  FYLL I DIN ADRESS.
                </div>
                <div className="text-center font-colby f16 fw100 px-3 py-1">
                  Och se om vi kan leverera till dig. Vi levererar till Nyköping!
                </div>
              </div>

              <div className="flex-col col-md-4 col-sm-12">
                <div className="text-center py-1">
                  <img className="" src="https://a.storyblok.com/f/100164/157x157/f3a6b7cce4/foodle_mat_ikon_svart.png" />
                </div>
                <div className="text-center font-bold font-colby text-black f20 py-1">
                  VÄLJ OCH VRAKA
                </div>
                <div className="text-center font-colby text-black f16 py-1">
                  Vi har åtta olika konceptkök du kan välja mellan! Hoppas du hittar något som passar dig.
                </div>
              </div>

              <div className="flex-col col-md-4 col-sm-12">
                <div className="text-center py-1">
                  <img className="" src="https://a.storyblok.com/f/100164/157x157/8e484a4c77/foodle_timer_ikon_svar.png" />
                </div>
                <div className="text-center font-bold font-colby text-black f20 py-1">
                  VÄNTA PÅ OSS!
                </div>
                <div className="text-center font-colby text-black f16 py-1">
                  Och se om vi kan leverera till dig. Vi levererar till Nyköping!
                </div>
              </div>

            </div>

            <div className="h80"></div>

            <div className="flex-row flex-center">
              <div className="whome-btn bg-white" value={ this.state.zip } onClick={() => this.unit_zip() }>BESTÄLL NU</div>
            </div>

            <div className="h80"></div>

          </section>

          <section className="" style={ this.bg2_style() }>
            <div className="text-center font-bold f32 py-5">
              Upptäck våra kök
            </div>

              <div className="wpx flex-row">
                <div className="mh-center-left" >
                  <FontAwesomeIcon className="foodle_circle foodle_circle_shadow" icon="circle-arrow-left" size="5x" onClick={() => this.mgs_sub() } />
                </div>
                <div className="hs">
              { this.state.mgo[this.state.mgs].map((mgo, key) =>
                  <div className={ this.mg_cls(mgo) } key={key}
                       onClick={() => this.mg_open(mgo) }
                       style={ this.mg_style(mgo) }
                  >
                    <div className="flex-col">
                      <img src={this.imgUrl(mgo.img)} className={mgo.class} alt="Foodle" />
                      <img src={this.imgUrl(mgo.txtimg)} className="foodle-txt px-5" alt="Foodle" />
                    </div>
                    <div className="flex-row px-5 py-3" >
                      <div>{ mgo.desc }</div>
                    </div>
                    <div className="flex-row px-5 py-3" >
                      <div className="flex"></div>
                      <div className="mg-btn" onClick={() => this.mg_open(mgo) }>TILL KÖK</div>
                      <div className="flex"></div>
                    </div>
                  </div>
              )}
                </div>
                <div className="mh-center-right" >
                  <FontAwesomeIcon className="foodle_circle foodle_circle_shadow" icon="circle-arrow-right" size="5x" onClick={() => this.mgs_add() } />
                </div>
              </div>

              <div className="flex-row flex-center py-3">
                <div className="bhollow-btn bg-white" value={ this.state.zip } onClick={() => this.unit_zip() }>ALLA VÅRA KÖK</div>
              </div>

            <div className="h80"></div>

          </section>

          <section className="" style={ this.bg3_style() }>
            <div className="text-center font-bold font-colby text-tomato f80 py-3">
              STORYN OM FOODLE
            </div>
            <div className="flex-row wp row">

              <div className="flex-col col-md-6 col-sm-12 flex-center">
                <div className="text-center px-2">
                Att äta en god måltid tillsammans med nära och kära kan vara dagens höjdpunkt.
                Men när det är ont om tid, inspirationen tryter och alla är oense om vad man ska äta kan det vara dagens jobbigaste stund…
                Att stå i köket och försöka laga hela familjens favoriträtter är nästan omöjligt.
                </div>
              </div>

              <div className="flex-col col-md-6 col-sm-12">
                <div className="text-center py-1">
                  <img className="" src="https://a.storyblok.com/f/100164/157x157/ce468b50a2/foodle_mat_ikon_red.png" />
                </div>
                <div className="flex-row flex-center">
                <div className="text-center font-bold font-feltthat f80 w350 py-1">
                  God, enkel mat för folket!
                </div>
                </div>
              </div>

            </div>

            <div className="flex-row flex-center">
              <div className="whollow-btn text-white" value={ this.state.zip } onClick={() => this.unit_zip() }>OM OSS</div>
            </div>

            <div className="h80"></div>
          </section>

          <section className="" style={ this.bg4_style() }>
            <div className="text-center font-bold font-colby f80 py-3">
              VANLIGA frågor
            </div>
            <div className="wp">
            <div className="accordion faq">
              <div style={ this.acc1_style() }></div>
              {this.state.accdata.map(({ id, title, content }) => (
                  <div className="flex-col">
                    <div className="flex-row txt_20" onClick={() => this.acc_set(id) } style={ this.acch_style(id) }>
                      <div className="flex">{title}</div>
                      <FontAwesomeIcon icon={ this.acc_icon(id) } size="lg" style={ this.acc_icon_style(id) } />
                    </div>
                    <div className="flex-row txt_16" style={ this.acc_style(id) }>
                      {content}
                    </div>
                  </div>
              ))}
              <div style={ this.acc2_style() }></div>
            </div>
            <div className="flex-row flex-left">
              <div className="txt_16" onClick={() => this.faq() }>FLER FRÅGOR OCH SVAR <FontAwesomeIcon icon="arrow-right" size="1x" /></div>
            </div>
            </div>

            <div className="h80"></div>
          </section>

        </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_Home);
