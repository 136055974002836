import React from 'react';
import * as q from '../../../lib/defer';

import sbo from '../sbo.js'

import socketIOClient from 'socket.io-client'

    var defer = {};

var url = 'http://127.0.0.1:9231/';
var sio = null;

export function srs_connect() {
    // DEBUG
    if(false) url = 'http://192.168.199.94:9150/';
    if(false) url = 'http://192.168.1.1:9550/';
    if(false) url = 'http://212.112.180.177:9150/';
    // BETA
    //if(true) url = 'http://212.112.180.177:9569/';
    if(false) url = 'http://212.112.180.177:9581/';
    if(false) url = 'https://services.svepos.se:9569/';
    // LIVE
    if(false) url = 'https://services.svepos.se:9550/';

    //alert(JSON.stringify(sbo.cdo.unit));
    if(sbo.cdo.unit == "sto") url = 'http://212.112.180.177:9581/';
    if(sbo.cdo.unit == "nyk") url = 'http://212.112.180.177:9582/';

    //const sio = socketIOClient(url, { rejectUnauthorized: false });
    //const sio = socketIOClient(url);
    sio = socketIOClient(url, {transports: ["websocket"]});
    //sio.emit('change color', this.state.color);

    sio.on('connect', function () {
        alert('Connected!');
        //toastr.success("IO CONNECTED");
    });
    sio.on('connect_error', function (e) {
        alert('Connect Error : ' + JSON.stringify(e));
        //toastr.error("IO CONNECT ERROR", e);
    });
    sio.on('error', function (e) {
        alert('Socket Error : ' + e);
        //toastr.error("IO ERROR", e);
    });

    sio.on('xx:msg', function (data) {
        alert(data);
    });

    sio.on('sapi:rsp', function (base64) {
        //alert(JSON.stringify(base64));
        //var s = atob(base64);
        var ret = base64;
        //var rsp = JSON.parse(s);
        var reqid = ret.reqid;
        var rsp = ret.rsp;
        //alert(JSON.stringify(reqid));
        //if(defer[reqid]) defer[reqid].resolve(rsp);
        if(defer[reqid]) defer[reqid].resolve(rsp);
    });
    sio.on('srs:rsp', function (base64) {
        //alert(JSON.stringify(base64));
        //var s = atob(base64);
        var rsp = base64;
        //var rsp = JSON.parse(s);
        var reqid = rsp.reqid;
        var rco = rsp.rco;
        //alert(JSON.stringify(reqid));
        //if(defer[reqid]) defer[reqid].resolve(rsp);
        if(defer[reqid]) defer[reqid].resolve(rco);
    });
}

export function sio_req(prm) {
    var sdo = {};

    var xdo = sessionStorage.getItem("svp");
    if(xdo) {
        sdo = JSON.parse(xdo);
    }
    else {
        sdo.token = "xx1122xx";
    }
    //sdo.token = "xx1122xx";
    //var req = {};
    var reqid = "1234567890123456".split('').map(function(){return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(62*Math.random()));}).join('');
    //var reqid = crypto.randomBytes(16).toString('hex');
    //var reqid = Math.random().toString(16).substr(2, 16);
    //var reqid = 1212;
    defer[reqid] = q.defer();
    var req = {};
    req.sid = prm.sid;
    req.req = prm.req;
    req.reqid = reqid;
    req.sub = "pos";
    req.token = sdo.token;
    req.token = sdo.token;
    req.prm = prm;
    //req.token = $sessionStorage.token;
    //var defer = q.defer();
    //alert(JSON.stringify(reqid));

    //var s = atob(base64);
    //var sreq = JSON.stringify(req);
    //var breq = btoa(sreq);
    //alert(JSON.stringify(breq));
    sio.emit("sapi:req", req, function () {
    });

    return defer[reqid].promise;
/*
    sio.req("sapi:req", rqo)
    .then(function(rsp) {
        //alert(JSON.stringify(rsp));
        defer.resolve(rsp);
    })
    .catch(function(e) {
        alert("ERR :" + JSON.stringify(e));
        defer.reject(e);
    });
*/
}
export function srs_req(req) {
    var sdo = {};

    var xdo = sessionStorage.getItem("svp");
    if(xdo) {
        sdo = JSON.parse(xdo);
    }
    else {
        sdo.token = "8796ac0586ec912fa584c530184c7ad1";
    }

    var reqid = "1234567890123456".split('').map(function(){return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(62*Math.random()));}).join('');
    defer[reqid] = q.defer();
    req.reqid = reqid;
    req.token = sdo.token;
    alert(JSON.stringify(req));
    sio.emit("srs:req", req, function () {
    });

    return defer[reqid].promise;
}
