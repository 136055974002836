import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../lib/ui/tiden";
import sbo from './sbo.js'

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Units extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";

      this.state.mga = [
          {id: 'dan', sid: 'S0000281', text: 'FOODLE DANDERYD', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'sic', sid: 'S0000283', text: 'FOODLE SICKLA', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'nyk', sid: 'S0000282', text: 'FOODLE NYKÖPING', img: '', txtimg: '', class: 'mg-sallad', desc: '' }
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      this.my_loc();
/*
      if ("geolocation" in navigator) {
          alert("Available");
      } else {
          alert("Not Available");
      }
      navigator.geolocation.getCurrentPosition(function(position) {
          alert(JSON.stringify(position));
          //alert("Latitude is :", position.coords.latitude);
          //alert("Longitude is :", position.coords.longitude);
      }, function(error) {
          alert("Error Code = " + error.code + " - " + error.message);
      });
*/
  }

    my_loc() {
        var self = this;
        fetch("http://ip-api.com/json")
        .then(response => response.json())
        .then(
            (rsp) => {
                //alert(JSON.stringify(rsp));
                //alert("RSP: " + JSON.stringify(rsp));
                var loco = {};
                loco.city = rsp.city;
                loco.zip = rsp.zip;
                //alert(JSON.stringify(loco));
                self.setState({ loco: loco });
            },
            (error) => {
                //alert("Err: " + JSON.stringify(error));
            }
        );
        return;
    }
    my_location() {
        var cls = "";
        var options = {
            enableHighAccuracy: true,
            timeout: 1000,
            maximumAge: 0
        };
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.cb_succces, this.cb_fail, options);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
        return cls;
    }
    cb_succces = function(position) {
        alert(JSON.stringify(position));
    }
    cb_fail = function(error) {
        alert(JSON.stringify(error));
    }
    mg_cls(btn) {
        var cls = "flex col";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }
    bg_style() {
        var css = {};
        css.margin = "0px auto";
        css.padding = "100px 20px";
        css.width = "100%";
        //css.backgroundImage = 'url("http://filpub.svepos.se/img/foodle/foodle_hero_startsida.jpeg")';
        //css.backgroundSize = "cover";
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      var url = "";
      url = "http://filpub.svepos.se/img/foodle/";
      url = url + fil;
      return url;
    }

    unit_open(mgo) {
        //sbo.cdo.unit = mgo.id;
        sbo.cdo.sid = mgo.sid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/foodle/kitchens";
        this.props.history.push(url);
    };
    unit_zip() {
        var sid = "";
        var zip = this.state.zip;
        //alert(zip);

        if(zip && (zip.substr(0, 2) == "11") ) {
            sid = "S0000281";
        }
        else if(zip && (zip.substr(0, 3) == "611") ) {
            sid = "S0000282";
        }
        else {
            alert("Ej aktivt postnummer");
            return;
        }
        //sbo.cdo.unit = mgo.id;
        sbo.cdo.zip = zip;
        sbo.cdo.sid = sid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/foodle/kitchens";
        this.props.history.push(url);
    };
    setZip(e) {
        //alert(e.currentTarget.value);
        this.setState({ zip: e.currentTarget.value});
    };
  render() {

    return (
    <div className="web-app">
        <div className="" style={ this.bg_style() }>

        <div className="mgu py-2">
            <div className="mgu-grid">
                { this.state.mga.map((mgo, key) =>
                    <div className="foodle-unit flex-col" key={key}
                         onDoubleClick={() => this.mg_open(mgo) }
                         style={ this.mg_style(mgo) }
                    >
                        <div className="flex-col flex">
                            <div className="flex-center">{ mgo.text }</div>
                            <div className="flex"></div>
                            <div className="mfull-btn" onClick={() => this.unit_open(mgo) }>TILL ENHET</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div style={{ height: "200px" }}></div>

        </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_Units);
