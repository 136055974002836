import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {withRouter} from "../lib/react/withRouter";

import * as utils from "../assets/utils";
import Tiden from "../lib/ui/tiden";

import './css/cdi.css';
import sbo from './sbo.js'

import Svepos_Home from './home.js';
import SveposLogo from "./img/logo-sk.png";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Massa extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.sid = "S1";
      this.state.wnds = {};
      var str = sessionStorage.getItem("sbo");
      this.state.sdo = JSON.parse(str);
      //alert(JSON.stringify(this.state.sdo));
      if(this.state.sdo) {
          if(this.state.sdo.cdo) sbo.cdo = this.state.sdo.cdo;
          if(this.state.sdo.cart) sbo.cart = this.state.sdo.cart;
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      //window.removeEventListener('scroll', this.wndScroll);
  }
  componentDidMount() {
      //window.addEventListener('scroll', this.wndScroll);
      var self = this;
      setTimeout(function (){
          self.slide_tick();
      }, 10000);
  }
    top_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        //if(this.state.top == 0) css.height = 35;
        return css;
    }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

    mnu_open(url) {
        //alert(url);
        this.props.navigate(url);
    }
    slide(sid) {
        var css = {};
        css.display = "none";
        if(sid == this.state.sid) css.display = "";
        return css;
    }
    slide_step(sid) {
        var nid = "S1";
        if(sid == "S1") nid = "S2";
        if(sid == "S2") nid = "S3";
        if(sid == "S3") nid = "S1";
        this.setState({ sid : nid });
    }
    slide_tick() {
        var self = this;
        var sid = this.state.sid;
        var nid = sid;
        if(sid == "S1") nid = "S2";
        if(sid == "S2") nid = "S3";
        if(sid == "S3") nid = "S1";
        //alert("SID: " + nid);
        this.setState({ sid : nid }, function() {
            setTimeout(function () {
                self.slide_tick();
            }, 10000);
        });
    }
  render() {

  return (
      <div className="web-app">

          <div className="svp-slide svp-s1"  style={ this.slide("S1") } onClick={() => this.slide_step("S1") }>
              <div className="svp-body svp-txt">

                  <div className="flex-col">
                      <div className="flex-row">
                          <div className="flex-center flex py-5">
                              <div className="py-3"><img src={SveposLogo} className="cursor-pointer svepos-logo" alt="Svepos"  onClick={() => this.fknGo("/svepos") } /></div>
                              <h1>SVERIGEKASSAN AB</h1>
                              <div className="horiz_split"></div>
                              <h1>PIZZAKASSAN</h1>
                          </div>
                      </div>
                  </div>

                  <div className="flex-col flex-center py-5">
                      <div className="txt-bold">Pizzakassan by Sverigekassan</div>
                      <div className="">Snabb enkelt det är ledordet i Pizzakassan som är framtagen för snabbmatsbranschen – enkel, stabilt och tryggt.</div>
                      <div className="">Men med stora möjligheter t.ex. bongskärmar, interaktiva tidstyrda menyskärmar kopplade till kassan, direkt koppling till Foodora</div>
                      <div className="">Pris från 799 kr/mån  </div>
                      <div className="txt-bold">Och det bästa av allt är att du kan själv välja inlösenavtal utan dolda avgifter.  </div>
                      <div className="">I grunduppsättningen ingår bland annat </div>
                      <div className="">Automatisk Sie4 export till Er bokföring  </div>
                      <div className="">Puckhantering</div>
                      <div className="">EMV kortläsare med möjlighet till Swish koppling</div>
                      <div className="">Lojalitetskort/Lunchkort</div>
                      <div className="">Driftsupport 24/7</div>
                  </div>
                  <div className="flex-col">
                      <div className="flex-row">
                          <div className="flex-center flex py-5">
                              <h1>EN NÖJD GÄST KOMMER ALLTID TILLBAKA</h1>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
          <div className="svp-slide svp-s2"  style={ this.slide("S2") } onClick={() => this.slide_step("S2") }>
              <div className="svp-body svp-txt">

                  <div className="flex-col">
                      <div className="flex-row">
                          <div className="flex-center flex py-5">
                              <div className="py-3"><img src={SveposLogo} className="cursor-pointer svepos-logo" alt="Svepos"  onClick={() => this.fknGo("/svepos") } /></div>
                              <h1>SVERIGEKASSAN AB</h1>
                              <div className="horiz_split"></div>
                              <h1>EVENTKASSAN</h1>
                          </div>
                      </div>
                  </div>

                  <div className="flex-col flex-center py-5">
                      <div className="txt-bold">Eventkassan by Sverigekassan</div>
                      <div className="">Stort eller smått spelar ingen roll!</div>
                      <div className="">Med eventkassan kan ni ha en eller hundratalskassor igång samtidigt och se all försäljning i realtid så ni kan aktivit se till att alla Era outlets aldrig får slut på produkter att sälja.</div>
                      <div className="">Det går att koppla en uppsjö olika delar till systemet bongskärmar i flera olika kök, interaktiva menyskärmar, bärbara serviskassor, individuella RFID betalningar osv. the sky is the limit. </div>
                      <div className="">Pris från 999 kr/mån per terminal  </div>
                      <div className="txt-bold">Och det bästa av allt är att du kan själv välja inlösenavtal utan dolda avgifter.</div>
                      <div className="">I grunduppsättningen ingår bland annat </div>
                      <div className="">Automatisk Sie4 export till Er bokföring</div>
                      <div className="">Puckhantering</div>
                      <div className="">EMV kortläsare med möjlighet till Swish koppling</div>
                      <div className="">Lojalitetskort/årskort</div>
                      <div className="">Driftsupport 24/7</div>
                  </div>

                  <div className="flex-col">
                      <div className="flex-row">
                          <div className="flex-center flex py-5">
                              <h1>EN NÖJD GÄST KOMMER ALLTID TILLBAKA</h1>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

          <div className="svp-slide svp-s3"  style={ this.slide("S3") } onClick={() => this.slide_step("S3") }>
              <div className="svp-body svp-txt">

                  <div className="flex-col">
                      <div className="flex-row">
                          <div className="flex-center flex py-5">
                              <div className="py-3"><img src={SveposLogo} className="cursor-pointer svepos-logo" alt="Svepos"  onClick={() => this.fknGo("/svepos") } /></div>
                              <h1>SVERIGEKASSAN AB</h1>
                              <div className="horiz_split"></div>
                              <h1>RESTAURANGKASSAN</h1>
                          </div>
                      </div>
                  </div>

                  <div className="flex-col flex-center py-5">
                      <div className="txt-bold">Restaurangkassan by Sverigekassan</div>
                      <div className="">De perfekt utvecklade POS-terminalen för restaurangbranschen som har samtliga funktioner du behöver för en modern restaurangverksamhet. Lägg till bongskärmar, bärbara </div>
                      <div className="">serviskassor så är ni verkligen top of the line och kan koncentrera Er på gästerna i stället för tekniken.</div>
                      <div className="">Pris från 1499 kr/mån per terminal</div>
                      <div className="txt-bold">Och det bästa av allt är att du kan själv välja inlösenavtal utan dolda avgifter.</div>
                      <div className="">I grunduppsättningen ingår bland annat </div>
                      <div className="">Automatisk Sie4 export till Er bokföring  </div>
                      <div className="">Bordshantering</div>
                      <div className="">Bärbar EMV kortläsare med möjlighet till Swish koppling</div>
                      <div className="">Lojalitetskort/årskort </div>
                      <div className="">Driftsupport 24/7</div>
                  </div>

                  <div className="flex-col">
                      <div className="flex-row">
                          <div className="flex-center flex py-5">
                              <h1>EN NÖJD GÄST KOMMER ALLTID TILLBAKA</h1>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

      </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Massa));

/*
background-color: rgb(244, 59, 36);


          <div className="flex-col flex-center py-5">
              <div className="">Sverigekassan har utvecklat kassasystem för hotell, restaurang och fastfood branschen sedan början av 80-talet vilket innebär att man verkligen kan säga att vi har erfarenhet av branschen.</div>
              <div className="">I vårt standardprogram för restaurang/bar/nattklubb & café finns bland annat funktioner med upptill 360 direktförsäljningsknappar, under menyer, bordshantering, dela/delbetala på bord/nota, köks & barbongar med meddelande, stekgrader, mini lager, lojalitetsystem, MultiEmv för snabba kortbetalningar, direktbetalningar mot samtliga upptänkliga betalsätt inkl. presentkortssystem.</div>
              <div className="">Och mycket mer.</div>
          </div>
          <div className="flex-col flex-center py-5">
              <div className="">Vårt kompletta kassaprogram i en kortterminal.</div>
              <div className="">Vår lösning betyder att ni inte behöver ha några ”vanliga kassaterminaler” allt finns i kortterminalen.</div>
              <div className="">Svepos A02 ger er möjlighet att ha upptill 360 direktförsäljning knappar, undermenyer, bordhantering, bongskrivare, köksskärmar, Dela nota/Splitta nota mm.</div>
              <div className="">Då hårdvarorna vi har valt ut har streckkodläsare, RFID och magnetsspårsläsare är Svepos A02 även det perfekta verktyget för butik och handel.</div>
              <div className="">Då vi kan hantera kundklubbar, presentkort och alla former av betalningar direkt i terminalen kan personalen arbeta där kunden är oavsett om ni driver en restaurang, foodtruck eller butiksverksamhet.</div>
              <div className="">Terminalen har uppkoppling via 3g/4g abonnemang och arbetar mot våra serverar och skatteverksbox i molnet.</div>
          </div>
          <div className="flex-col flex-center py-5">
              <div className="">Med Svepos A02 har du ett robustkassasystem i framkant som är utvecklat för användaren utan att rubba på kontrollen för ägaren.</div>
              <div className="">Systemet går lika bra att använda med en kassaterminal - inmatningsenhet eller upp skalat med flera inmatningsenheter i ett stort system.</div>
              <div className="">Du kan kombinera fritt mellan de olika modellerna som finns i vår breda portfölj och ändå jobba i samma system.</div>
              <div className="">Med MultiEmv kan ni koppla obegränsat med kortterminaler till en kassaskärm för effektiv restaurang och barhantering.</div>
          </div>

*/