import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../../lib/ui/tiden";
import FoodleLogo from "../img/foodle-logo.svg";


function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_About extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  pg_style() {
    var css = {};
    css.background = "#ff92d4";
    css.color = "#f43b24";
    css.font = "Colby";
    css.padding = "10px auto";
    return css;
  }
  ctx_style() {
    var css = {};
    css.width = "450px";
    return css;
  }

  render() {

    return (
    <div className="web-app">
      <footer className="bg-tomato">
        <div className="max-w-screen-lg mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="mt-12 grid grid-cols-1 justify-items lg:grid-cols-4 gap-8 xl:mt-0 xl:col-span-2 text-center lg:text-left gap-y-20">
            <div className="logo-image space-y-8 xl:col-span-1 flex justify-center lg:justify-start" data-v-1bac084d="">
              <img alt="Foodle" className="h-8 lazyLoad isLoaded" src={FoodleLogo} /></div>
            <div>
              <ul className="space-y-4">
                <li>
                  <a href="/om-foodle" className="text-white">OM FOODLE</a>
                </li>
                <li>
                  <a href="/faq" className="text-white nuxt-link-exact-active nuxt-link-active" aria-current="page">FAQ</a>
                </li>
                <li>
                  <a href="/kontakt" className="text-white">KONTAKT</a>
                </li>
              </ul>
            </div>
            <div data-v-1bac084d="">
              <ul className="space-y-4" data-v-1bac084d="">
                <li data-v-1bac084d=""><a href="/regler-och-villkor" className="text-white" data-v-49eccc0e=""
                                          data-v-1bac084d="">
                  REGLER OCH VILLKOR
                </a></li>
                <li data-v-1bac084d=""><a href="/cookies-policy" className="text-white" data-v-49eccc0e=""
                                          data-v-1bac084d="">
                  COOKIES
                </a></li>
              </ul>
            </div>
            <div>
              <ul className="space-y-4">
                <li>
                  <a href="https://www.facebook.com/Foodleonline/" target="_blank" className="text-white">
                  <div className="flex flex-col lg:flex-row gap-y-1" data-v-49eccc0e="" data-v-1bac084d="">
                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                         className="w-6 h-6 inline mr-2 place-self-center svg-inline--fa fa-facebook-f fa-w-10"
                         data-v-49eccc0e="" data-v-1bac084d="">
                      <path fill="currentColor"
                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                            data-v-49eccc0e="" data-v-1bac084d="" className=""></path>
                    </svg>
                    <div data-v-49eccc0e="" data-v-1bac084d="">FACEBOOK</div>
                  </div>
                </a></li>
                <li data-v-1bac084d=""><a href="https://www.instagram.com/foodleonline/" target="_blank"
                                          className="text-white" data-v-49eccc0e="" data-v-1bac084d="">
                  <div className="flex flex-col lg:flex-row gap-y-1" data-v-49eccc0e="" data-v-1bac084d="">
                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                         className="w-6 h-6 inline mr-2 place-self-center svg-inline--fa fa-instagram fa-w-14"
                         data-v-49eccc0e="" data-v-1bac084d="">
                      <path fill="currentColor"
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                            data-v-49eccc0e="" data-v-1bac084d="" className=""></path>
                    </svg>
                    <div data-v-49eccc0e="" data-v-1bac084d="">INSTAGRAM</div>
                  </div>
                </a></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 pt-8" data-v-1bac084d=""><p
              className="leading-6 text-white px-20 text-center lg:text-left lg:px-0" data-v-1bac084d="">
            © Foodle AB 2022
          </p></div>
        </div>
      </footer>
      </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_About);
