import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../../lib/ui/tiden";
import sbo from './sbo.js'
import * as utils from "../../assets/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClock } from '@fortawesome/free-regular-svg-icons'
import Fr_Input from "../../lib/ui/fr_input";
import * as srs from "../../lib/srs";
import Foodle_Pay from "./pay";
import Foodle_PayOk from "./pay_ok";
import Foodle_PayFail from "./pay_fail";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Direct extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";
      this.state.adress = "";
      this.state.discount = "";
      this.state.wnds = {};

      this.state.fnamn = "";
      this.state.enamn = "";
      this.state.tele = "";
      this.state.email = "";
      this.state.msg = "";

      this.state.mga = [
          {id: 'dan', sid: 'S0000281', text: 'FOODLE DANDERYD', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'sic', sid: 'S0000283', text: 'FOODLE SICKLA', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'nyk', sid: 'S0000282', text: 'FOODLE NYKÖPING', img: '', txtimg: '', class: 'mg-sallad', desc: '' }
      ];
      this.state.dlt = [
          {id: '1', ftid: '1300', ttid: '1330', text: '' },
          {id: '2', ftid: '1330', ttid: '1400', text: '' },
          {id: '3', ftid: '1400', ttid: '1430', text: '' },
          {id: '4', ftid: '1430', ttid: '1500', text: '' },
          {id: '5', ftid: '1500', ttid: '1530', text: '' },
          {id: '6', ftid: '1530', ttid: '1600', text: '' },
      ];
      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }

      sbo.cart.levbelopp = 0;
      if(sbo.cart.levtyp == "deliver") {
          sbo.cart.levbelopp = 3900;
      }

      //alert(JSON.stringify(sbo.cdo));
      if(sbo.cdo) {
          this.state.fnamn = sbo.cdo.fnamn;
          this.state.enamn = sbo.cdo.enamn;
          this.state.tele = sbo.cdo.tele;
          this.state.email = sbo.cdo.email;
          this.state.msg = sbo.cdo.msg;
          this.state.street = "";
          this.state.city = "";
      }
      //if(!sbo.cdo) sbo.cdo = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {


    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      this.my_loc();
/*
      if ("geolocation" in navigator) {
          alert("Available");
      } else {
          alert("Not Available");
      }
      navigator.geolocation.getCurrentPosition(function(position) {
          alert(JSON.stringify(position));
          //alert("Latitude is :", position.coords.latitude);
          //alert("Longitude is :", position.coords.longitude);
      }, function(error) {
          alert("Error Code = " + error.code + " - " + error.message);
      });
*/
  }

    my_loc() {
        var self = this;
        fetch("http://ip-api.com/json")
        .then(response => response.json())
        .then(
            (rsp) => {
                //alert(JSON.stringify(rsp));
                //alert("RSP: " + JSON.stringify(rsp));
                var loco = {};
                loco.city = rsp.city;
                loco.zip = rsp.zip;
                //alert(JSON.stringify(loco));
                self.setState({ loco: loco });
            },
            (error) => {
                //alert("Err: " + JSON.stringify(error));
            }
        );
        return;
    }
    my_location() {
        var cls = "";
        var options = {
            enableHighAccuracy: true,
            timeout: 1000,
            maximumAge: 0
        };
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.cb_succces, this.cb_fail, options);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
        return cls;
    }
    cb_succces = function(position) {
        alert(JSON.stringify(position));
    }
    cb_fail = function(error) {
        alert(JSON.stringify(error));
    }
    mg_cls(btn) {
        var cls = "flex col";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }
    bg_style() {
        var css = {};
        //css.margin = "0px auto";
        //css.padding = "100px 20px";
        //css.width = "100%";
        css.background = "#fee260";
        //css.backgroundImage = 'url("http://filpub.svepos.se/img/foodle/foodle_hero_startsida.jpeg")';
        //css.backgroundSize = "cover";
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      var url = "";
      url = "http://filpub.svepos.se/img/foodle/";
      url = url + fil;
      return url;
    }

    unit_open(mgo) {
        //sbo.cdo.unit = mgo.id;
        sbo.cdo.sid = mgo.sid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/foodle/kitchens";
        this.props.history.push(url);
    };
    unit_zip() {
        var sid = "";
        var zip = this.state.zip;
        //alert(zip);

        if(zip && (zip.substr(0, 2) == "11") ) {
            sid = "S0000281";
        }
        else if(zip && (zip.substr(0, 3) == "611") ) {
            sid = "S0000282";
        }
        else {
            alert("Ej aktivt postnummer");
            return;
        }
        //sbo.cdo.unit = mgo.id;
        sbo.cdo.zip = zip;
        sbo.cdo.sid = sid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/foodle/kitchens";
        this.props.history.push(url);
    };
    setZip(e) {
        //alert(e.currentTarget.value);
        this.setState({ zip: e.currentTarget.value});
    };
    imgUrl(fil) {
        var url = "";
        url = "http://filpub.svepos.se/img/foodle/";
        url = url + fil;
        return url;
    }
    row_del(row) {
        sbo.cart.belopp = 0;
        if(!sbo.cart.rows) sbo.cart.rows = [];

        //sbo.cart.rows = sbo.cart.rows.splice(key - 1, 1);
        var belopp = 0;
        var rows = [];
        for(var crow of sbo.cart.rows) {
            if(crow.plu != row.plu) {
                //alert(JSON.stringify(crow));
                belopp = belopp + (crow.belopp * 1);
                rows.push(crow);
            }
        }
        sbo.cart.rows = rows;
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    pro_ixa(row) {
        var a = [];
        if(row.pro.ixo) a = Object.values(row.pro.ixo);
        return a;
    }
    pro_txa(row) {
        var a = [];
        if(row.pro.txo) a = Object.values(row.pro.txo);
        return a;
    }
    pro_sxa(row) {
        var a = [];
        if(row.pro.sxo) a = Object.values(row.pro.sxo);
        return a;
    }
    pro_dxa(row) {
        var a = [];
        if(row.pro.dxo) a = Object.values(row.pro.dxo);
        return a;
    }
    discount_change(e) {
        this.setState({discount: e});
    }
    val_save( id, val ) {
        var state = this.state;
        state[id] = val;
        this.setState(state);
    }

    discount_clear() {
        var belopp = sbo.cart.belopp * 1;
        var discount = sbo.cart.discount * 1;
        belopp = belopp + discount;
        discount = 0;

        delete(sbo.cart.discount);
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
        this.setState({discount: ""});
    }
    cart_discount() {
        if(sbo.cart.discount) {
            return;
        }
        var rproc = 10;
        if(this.state.discount == "rab50") rproc = 50;

        var discount = 0;
        var belopp = sbo.cart.belopp;
        belopp = belopp.replaceAll(".", "");
        belopp = belopp * 1;

        discount = belopp * (rproc / 100);
        belopp = belopp - discount;

        sbo.cart.discount = discount.toString();
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
        this.setState({discount: ""});
    }
    cart_pay() {
        if(false) {
            this.cart_do();
            return;
        }

        if(true) {
            //alert(JSON.stringify(sbo.cdo));
            //sbo.cart.cdo = {};
            sbo.cdo.fnamn = this.state.fnamn;
            sbo.cdo.enamn = this.state.enamn;
            sbo.cdo.tele = this.state.tele;
            sbo.cdo.email = this.state.email;
            sbo.cdo.msg = this.state.msg;
            sessionStorage.setItem("sbo", JSON.stringify(sbo));
        }

        var self = this;
        var wnds = {};
        wnds.cartpay = true;
        wnds.cb = this.cart_cb;
        this.setState({ wnds: wnds });
    }
    cart_cb(ctx, rsp) {
        //alert(JSON.stringify(rsp));
        if(rsp.ok == "000") {
            var wnds = {};
            wnds.cartpayok = true;
            wnds.cb = ctx.payok_cb;
            ctx.setState({ wnds: wnds });
        }
        else {
            var wnds = {};
            wnds.cartpayfail = true;
            //wnds.cb = ctx.payok_cb;
            ctx.setState({ wnds: wnds });
        }

        //else ctx.setState({ wnds: {} });
    }
    payok_cb(ctx, rsp) {
        ctx.setState({ wnds: {} });
        if(true) {
            var url = "/foodle";
            ctx.props.history.push(url);
            return;
        }
    }
    back() {
        if(true) {
            var url = "/foodle/delivery";
            this.props.history.push(url);
            return;
        }
    }
    cart_do() {
        var self = this;

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = "S0000091";
        prm.req = "vcm.ps.ps_ecom_sell";
        prm.sbo = sbo;

        alert(JSON.stringify(sbo));
        return;
        srs.sio_req(prm)
        .then(function(ret){
            alert(JSON.stringify(ret));

            //self.setState({ mpa: ret.rca });
            //self.syncTb("mnaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }
    not_min() {
        var brc = false;
        var minbelopp = 1000;
        var belopp = sbo.cart.belopp * 1;
        if(belopp < minbelopp) {
            brc = true;
        }
        return brc;
    }
  render() {

    return (
    <div className="web-app">

        <div className="wp flex-row px-5 py-3">
            <div className="flex">
            <div className="txt_18 cursor-pointer" onClick={() => this.back() }><FontAwesomeIcon icon="arrow-left" size="1x" /> Tillbaka</div>
            </div>
            <div className="txt_22">KASSA</div>
            <div className="flex"></div>
        </div>
        <div className="" style={ this.bg_style() }>
            <div className="dlt-body">
                <div className="flex-col py-5 px-5">

                    <div className="mpg">
                        <div className="flex-row">
                            <div className="txt_b16">Produkter</div>
                        </div>
                        <div className="flex-col px-2">
                            { sbo.cart.rows.map((crow, ckey) =>
                                <div className="flex-col py-2 cart-item" >
                                    <div className="flex-row" >
                                        <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                        <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.pris) } kr</div>
                                    </div>
                                    { this.pro_ixa(crow).map((row, key) =>
                                        <div className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_txa(crow).map((row, key) =>
                                        <div className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_sxa(crow).map((row, key) =>
                                        <div className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    { this.pro_dxa(crow).map((row, key) =>
                                        <div className="flex-row" >
                                            <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                            <div className="flex"></div>
                                            <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                            <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                        </div>
                                    )}
                                    <div className="flex-row" >
                                        <div className="txt_16">Summa</div>
                                        <div className="flex"></div>
                                        <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                                    </div>
                                    <div className="flex-row" >
                                        <div className="flex"></div>
                                        <FontAwesomeIcon icon="trash" size="lg" onClick={() => this.row_del(crow) } />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    { !sbo.cart.discount ?
                    <div className="">
                    <div className="flex-row">
                        <div className="txt_b16">ANGE RABATTKOD</div>
                    </div>
                    <div className="flex-row">
                        <Fr_Input col noflex cls="col-md-3 col-sm-6" className="text-left foodle-input" text={this.state.discount} getValue={(e)=> this.discount_change(e) } />
                        <div className="gray-btn col-md-3 col-sm-6" onClick={() => this.cart_discount()}>LÄGG TILL</div>
                    </div>
                    </div>
                    : null }
                    { sbo.cart.discount ?
                    <div className="flex-row" onClick={() => this.discount_clear()}>
                        <div className="txt_18">AVGÅR RABATT</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( sbo.cart.discount ) } kr</div>
                    </div>
                    : null }

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    <div className="flex-row">
                        <div className="txt_18">ATT BETALA</div>
                        <div className="flex"></div>
                        <div className="txt_b18">{ utils.toPris( sbo.cart.belopp ) } kr</div>
                    </div>
                    <div className="flex-row">
                        <div className="txt_14">Leveranskostnad</div>
                        <div className="flex"></div>
                        <div className="txt_b14">{ utils.toPris( sbo.cart.levbelopp ) } kr</div>
                    </div>

                </div>
            </div>

            <div className="flex-row py-3">
                <div className="flex"></div>
            </div>

                {this.not_min() ?
                    <div className="flex-row">
                        <div className="mpay-btn" onClick={() => this.cart_pay()}>MINI BELOPP EJ UPPNÅTT</div>
                    </div>
                    :
                    <div className="flex-row">
                        <div className="mpay-btn" onClick={() => this.cart_pay() }>VÄLJ BETALSÄTT</div>
                    </div>
                }
                <div className="flex-row py-3">
                    <div className="flex"></div>
                </div>

        </div>
        { this.state.wnds.cartpay ? <Foodle_Pay wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cartpayok ? <Foodle_PayOk wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
        { this.state.wnds.cartpayfail ? <Foodle_PayFail wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_Direct);
