import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import './css/order.css';
import SDOContext from '../sdo.js'

import Order_Top from './com/order_top.js';
import Order_Mnu from './com/order_mnu.js';
import Order_Foot from './com/order_foot.js';

import Order_Dash from './dash.js';
import Order_Menu from './menu.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";
import Sub_Massa from "../cdi/massa";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Order extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = { pgid: 11, mnu_show: true, deps: [] };
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.props.fetchWells()
    //  .then(res => this.setState({ wells: res.wells }) )
    var deps = [];
    deps.push( {"id": "01", "text": "DEP X1" } );
    deps.push( {"id": "02", "text": "DEP X2" } );
    deps.push( {"id": "03", "text": "DEP X3" } );

    //this.props.store.seo.cs = "5";
    ///this.props.store.seo.unit = "BSENHET";

    var pos = {};
    pos.deps = deps;
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      //this.pos_init();
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    pos_init() {
    var self = this;

	var req = {};
	req.req = "pos.pos_fkn.pos_init";

    //gda.wndLoading("Hämtar");
    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //var pos = {};
        //pos.deps = ret.rco.deps;
        //self.props.store.pos = pos;
        if(!self.props.store.pos) self.props.store.pos = {};
        self.props.store.pos.deps = ret.rco.deps;
        self.props.store.pos.vgrps = ret.rco.vgrps;

        //self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }

  render() {

    return (
    <div className="web-app">
    <div className="fixed-top web-top">
        <Order_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    <div className="web-content">
          <Routes>
              <Route path="menu/*" element={ <Order_Menu store={this.store} />} />
              <Route path="*" element={ <Order_Dash store={this.store} />} />
          </Routes>
    </div>

    </div>

    </div>


    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Order);

/*
        <Order_Foot store={this.store} />

          <Route path="/xx" component={pg_s1} />
          <Route path="/pos/yy" component={pg_s2} />
          <Route path="/cas/" component={pg_s2} />
    <p>KASINO : { this.state.status }</p>
    <div className="flex-row">
    <button className="btn btn-hw-blue w150 ripple" onClick={this.udid_req} aria-label="System" >
        Fråga
    </button>
    <button className="btn btn-hw-blue w150 ripple" onClick={this.settle} aria-label="System" >
        Settle
    </button>
    </div>
*/