import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";

function Order_Top(props) {
    let navigate = useNavigate();
    let [state, setState] = useState({wnds: {}, ma: []});

    var seo = props.store.seo;

    var selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'ses', text: 'MIN SESSION'},
    ];
    if(seo.cs > 0) {
        selitems.push( {fkn: 'ccs', text: 'CENTRALSYSTEM'} );
    }
    if(seo.auth >=90) {
        selitems.push( {fkn: 'man', text: 'MANAGER'} );
    }
    selitems.push( {fkn: 'logout', text: 'LOGGA UT'} );

    var it = {};

    var fa = "fa fa-key";
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const wnds_close = () => {
        setState({wnds: {}});
    };

    const goFull = () => {
        alert("Full");
    };

    function logout() {
        var url = "/logout";
        navigate(url);
    };
      function xlogout() {
        var self = this;
        var xdo = sessionStorage.getItem("svp");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:logout";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }
            setAnchorEl(null);
            sessionStorage.removeItem("svp");
            props.store.seo = {};

            var url = "/";
            navigate(url);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGOUT ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
      };
      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };
      const header_class = () => {
  	    var cls = "flex_col";

		if(false) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";


		return cls;
    }
  function fknGo(url) {
    //alert(row.href);
    navigate(url);
  }
  function fkn_cs() {
      var wnds = {};
      wnds.ccs = true;
      wnds.backdrop = true;
      setState({"wnds": wnds});
  }
  function fkn_manager() {
      var wnds = {};
      wnds.manager = true;
      wnds.backdrop = true;
      setState({"wnds": wnds});
  }
  function sel_fkn(sel) {
    //alert(JSON.stringify(fkn));
      if(sel.fkn == "home") {
        var url = "/hello";
        navigate(url);
      }
      if(sel.fkn == "setup") {
          var wnds = {};
          wnds.setup = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ses") {
          var wnds = {};
          wnds.ses = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ccs") {
          var wnds = {};
          wnds.ccs = true;
          wnds.backdrop = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "man") {
          var wnds = {};
          wnds.manager = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "logout") {
        var url = "/logout";
        navigate(url);
      }
  }

    var html =
        <div className={ header_class() }>
            <div className="web-top-content flex-row">
                <div className="web-top-icon px-3 w60" onClick={() => mnu_toggle() }>
                </div>
                <div className="web-top-mnu flex-column" onClick={() => fkn_manager() }>
                    <div className="item-sys">Order System</div>
                    <div className="item-fkn">{ props.store.fkn.name }</div>
                </div>
                <div className="flex-fill"></div>
                { props.store.seo.cs > 0 ?
                    <div className="" onClick={() => fkn_cs() }>
                        <div className="">TYP: { props.store.seo.cs }</div>
                        <div className="">ENHET: { props.store.seo.sid }</div>
                    </div>
                    : null
                }

                <div className="flex-fill"></div>
                <div className="web-top-usr flex-column" onClick={() => fknGo("/hello") }>
                </div>
                <div className="web-top-btn flex-column" onClick={() => fknGo("/hello") }>
                    <FontAwesomeIcon icon="utensils" size="fa-lg" color="grey"/>
                </div>
                <div className="web-top-btn flex-column" onClick={() => fknGo("/hello") }>
                    <FontAwesomeIcon icon="cog" size="fa-lg" color="grey"/>
                </div>
                <div className="web-top-btn flex-column" onClick={() => fknGo("/hello") }>
                    <FontAwesomeIcon icon="info" size="fa-lg" color="grey" />
                </div>
                <div className="web-top-sep"></div>
                <div className="">
                    <Dd_Sel items={ selitems } cls="web-top-icon" getValue={(e)=> sel_fkn(e)} />
                </div>
                <div className=""></div>
            </div>

        </div>
    ;
    return html;
}

export default observer(Order_Top);
