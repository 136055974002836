import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {withRouter} from "../lib/react/withRouter";

import * as utils from "../assets/utils";
import Tiden from "../lib/ui/tiden";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Terms extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.sid = "S1";
      this.state.wnds = {};
      var str = sessionStorage.getItem("sbo");
      this.state.sdo = JSON.parse(str);
      //alert(JSON.stringify(this.state.sdo));
      if(this.state.sdo) {
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      //window.removeEventListener('scroll', this.wndScroll);
  }
  componentDidMount() {
      //window.addEventListener('scroll', this.wndScroll);
  }
    top_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        //if(this.state.top == 0) css.height = 35;
        return css;
    }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

    mnu_open(url) {
        //alert(url);
        this.props.navigate(url);
    }
    slide(sid) {
        var css = {};
        css.display = "none";
        if(sid == this.state.sid) css.display = "";
        return css;
    }
    slide_step(sid) {
        var nid = "S1";
        if(sid == "S1") nid = "S2";
        if(sid == "S2") nid = "S3";
        if(sid == "S3") nid = "S1";
        this.setState({ sid : nid });
    }
    slide_tick() {
        var self = this;
        var sid = this.state.sid;
        var nid = sid;
        if(sid == "S1") nid = "S2";
        if(sid == "S2") nid = "S3";
        if(sid == "S3") nid = "S1";
        //alert("SID: " + nid);
        this.setState({ sid : nid }, function() {
            setTimeout(function () {
                self.slide_tick();
            }, 10000);
        });
    }
  render() {

  return (
      <div className="web-app">

          <div className="svp-body py-5">

              <h3>REGLER OCH VILLKOR</h3>

              <div className="svp-head">ALLMÄNNA VILLKOR</div>
              Allmänna villkor och bestämmelser

              <div className="svp-head">PERSONUPPGIFTER</div>
              CDI lagrar all information som anges vid beställning. Denna information behövs för att kunna erbjuda en trygg och bra tjänst. Läs mer om hur vi hanterar dina personuppgifter i vår personuppgiftspolicy längre ner.

              <div className="svp-head">BESTÄLLNING</div>
              All personinformation som anges i beställningsformuläret ska vara korrekt och tillhöra den som beställer produkterna. CDI ansvarar inte för utebliven leverans till följd av otillräcklig eller felaktig information i beställningsformuläret.

              <div className="svp-head">PRODUKTER</div>
              CDI har som mål att alltid hålla sidan uppdaterad för eventuella förändringar i produktutbudet men vi reserverar oss för att vissa produkter kan vara slut i lager.

              <div className="svp-head">BETALNING</div>
              Alla beställningar via CDI är bindande. Betalning ska ske i svenska kronor.
              Alla priser på CDI är inklusive moms om inget annat anges.

              <div className="svp-head">LEVERANS</div>
              CDI och alla inblandade parters mål är att erbjuda kortast möjliga leveranstid.
              Kunden har ansvar att ange korrekta adress- och kontaktuppgifter.
              Om kunden ej kan nås på angivet telefonnummer är beställningen inkorrekt genomförd och ingen vidare leveransskyldighet föreligger.
              För utebliven leverans till följd av att kunden ej kunnat nås utgår ingen ersättning från CDI.

              <div className="svp-head">ÅNGERRÄTT</div>
              Obruten förpackning eller outnyttjad licens kan ångras inom 10 dagar.

              <div className="svp-head">MISSBRUK</div>
              CDI lagrar kundens IP-adress och annan värdefull information om kundens Internetleverantör för att vid eventuellt missbruk av tjänsten kunna spåra upp beställaren. Vi polisanmäler och utreder alla typer av missbruk av våra tjänster på CDI.

              <div className="svp-head">TILLÄMPLIG LAG OCH TVIST</div>
              Svensk rätt ska tillämpas på dessa villkor. Tvister med anledning av detta avtal ska hänskjutas till medling och/eller skiljeförfarande i enlighet med Västsvenska Handelskammarens Förtroenderåds regler om medling och skiljeförfarande. Skiljeförfarandets säte ska vara Göteborg, Sverige.

              <div className="svp-head">VILLKORSÄNDRINGAR</div>
              CDI har rätt att ändra dessa villkor utan att i förväg få ditt godkännande. Informationen lämnas på webbplatsen. Genom att fortsätta att nyttja CDI efter att ändringen är genomförd godkänner du ändringen.

              <div className="svp-head">KONTAKTUPPGIFTER</div>
              CDI System AB, org nr 556573-8266, kan du kontakta på info@cdisystem.com.
              Vi följer Allmänna reklamationsnämndens rekommendationer

          </div>
      </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Terms));
