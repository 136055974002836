import React, { useState, useEffect, useContext } from 'react';

import * as srs from "./lib/srs";

import Fr_Input from "../../lib/ui/fr_input";
import Fr_Select from "../../lib/ui/fr_select";
import * as utils from "../../assets/utils";

import sbo from './sbo.js'

import FoodleCheck from "./img/checkmark.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Foodle_Pay from "./pay.js";

class Foodle_Cart extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];

    this.state.wnds = {};
    this.state.msg = {};

    this.state.pro = {};
    this.state.pbo = {};
    if(props.wnds.pbo) {
        //this.state.pbo = props.wnds.pbo;
    }
      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }

    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];
    this.state.debcre = [
      {id: '0', text: 'KREDIT'},
      {id: '1', text: 'DEBET'}
    ];
    this.state.tia = [
      {id: '0', text: 'Extra tomatsås', price: '1000'},
      {id: '1', text: 'Extra Majo', price: '1000'}
    ];
    this.state.tba = [
      {id: '0', text: 'Dippsås Vitlök', price: '1000'},
      {id: '1', text: 'Dippsås Majo', price: '1000'}
    ];
    this.state.soa = [
      {id: '0', text: 'Lökringar', price: '1900'},
      {id: '1', text: 'Pommes', price: '2500'}
    ];
    this.state.dra = [
      {id: '0', text: 'MER Appelsin', price: '1500'},
      {id: '1', text: 'Coca Cola', price: '2000'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
    //if(!this.props.wnds.pbo) this.pbo_init();
  }

    pbo_init() {
        var pbo = {};
        pbo.text = "";
        pbo.price = "";
        //alert(JSON.stringify(rso));
        this.setState({ pbo: pbo });
        return;
    }

    dlg_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.height = window.innerHeight * 0.8;
      return css;
    }

    wnd_close = () => {
      if(this.props.wnds.cb) {
          var rsp = {};
          rsp.ok = "999";
          this.props.wnds.cb(this.props.ctx, rsp);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  val_save(id, val) {
      var eko = this.state.eko;
      eko[id] = val;
        this.setState({ eko: eko });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    cart_reset() {
        var self = this;

        sbo.cart.belopp = "0";
        sbo.cart.rows = [];
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    xcart_pay() {
        var self = this;

        this.cart_sell();
    }

    cart_sell() {
    var self = this;

    var rows = sbo.cart.rows;
    var belopp = sbo.cart.belopp;
    if(!rows || (rows.length < 1)) {
        alert("INGET ATT SÄLJA");
        return;
    }

    var prm = {};
    prm.req = "vcm.ps.ps_web_sell";
    prm.avd = "01";
    prm.tablenr = "9001";
    prm.cashier = "00";
    prm.betkod = "card";
    prm.bettxt = "CARD PAYMENT";
    prm.belopp = belopp;
    prm.rows = rows;

    //net.sio_req(prm)
    srs.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    imgUrl(fil) {
        var url = "";
        url = "http://filpub.svepos.se/img/foodle/";
        url = url + fil;
        return url;
    }
    row_del(row) {
        sbo.cart.belopp = 0;
        if(!sbo.cart.rows) sbo.cart.rows = [];

        //sbo.cart.rows = sbo.cart.rows.splice(key - 1, 1);
        var belopp = 0;
        var rows = [];
        for(var crow of sbo.cart.rows) {
            if(crow.plu != row.plu) {
                belopp = belopp + (crow.belopp * 1);
                rows.push(crow);
            }
        }
        sbo.cart.rows = rows;
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    pro_ixa(row) {
        var a = [];
        if(row.pro.ixo) a = Object.values(row.pro.ixo);
        return a;
    }
    pro_txa(row) {
        var a = [];
        if(row.pro.txo) a = Object.values(row.pro.txo);
        return a;
    }
    pro_sxa(row) {
        var a = [];
        if(row.pro.sxo) a = Object.values(row.pro.sxo);
        return a;
    }
    pro_dxa(row) {
        var a = [];
        if(row.pro.dxo) a = Object.values(row.pro.dxo);
        return a;
    }
    cart_pay() {
        var self = this;
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    }
    zcart_pay() {
        var self = this;
        var wnds = {};
        wnds.cartpay = true;
        wnds.cb = this.cart_cb;
        this.setState({ wnds: wnds });
    }
    cart_cb(ctx, rsp) {
        if(rsp.ok == "000") {
            ctx.props.ctx.setState({ wnds: {} });
        }
    }
    not_min() {
      var brc = false;
      var minbelopp = 1000;
      var belopp = sbo.cart.belopp * 1;
        if(belopp < minbelopp) {
            brc = true;
        }
        return brc;
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";

	var html =
        <div className="mx_base">
        <div className="m2_backdrop" />
        <div className="mx_right flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">
            <div className="mx-close-btn" onClick={() => this.wnd_close() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

            <div className="flex-cl py-3">
            <div className="flex-row px-5">
                <div className="txt_b18">Varukorg</div>
                <div className="flex"></div>
            </div>
            <div className="flex-row px-5">
                <div className="txt_14">Antal varor : { sbo.cart.rows.length }</div>
            </div>
            </div>
            <div className="divider_orange"></div>

            <div className="mx-body px-5">

            <div className="mpg">
                <div className="flex-row">
                    <div className="txt_b16">Leverans uppgifter</div>
                </div>
                <div className="flex-col py-3 box-lev">
                    <div className="flex-row">
                        <div className="txt_14">{ sbo.cdo.street }</div>
                        <div className="flex"></div>
                        <div className="txt_14">Ändra</div>
                    </div>
                    <div className="txt_14">Stad</div>
                </div>
            </div>

            <div className="mpg">
                <div className="flex-row">
                    <div className="txt_b16">Produkter</div>
                </div>
                <div className="flex-col px-2">
                    { sbo.cart.rows.map((crow, ckey) =>
                        <div className="flex-col py-3 cart-item" >
                            <div className="flex-row" >
                                <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                <div className="flex"></div>
                                <div className="txt_16">{ utils.toPris(crow.pris) } kr</div>
                            </div>
                            { this.pro_ixa(crow).map((row, key) =>
                                <div className="flex-row" >
                                    <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                    <div className="flex"></div>
                                    <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                    <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                </div>
                            )}
                            { this.pro_txa(crow).map((row, key) =>
                                <div className="flex-row" >
                                    <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                    <div className="flex"></div>
                                    <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                    <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                </div>
                            )}
                            { this.pro_sxa(crow).map((row, key) =>
                                <div className="flex-row" >
                                    <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                    <div className="flex"></div>
                                    <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                    <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                </div>
                            )}
                            { this.pro_dxa(crow).map((row, key) =>
                                <div className="flex-row" >
                                    <div className="txt_14">{ utils.toLang(row.m0text) }</div>
                                    <div className="flex"></div>
                                    <div className="txt_14">{ utils.toNum(row.m0nrof) } * </div>
                                    <div className="txt_14">{ utils.toPris(row.m0pris) } kr</div>
                                </div>
                            )}
                            <div className="flex-row" >
                                <div className="txt_16">Summa</div>
                                <div className="flex"></div>
                                <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                            </div>
                            <div className="flex-row" >
                                 <div className="flex"></div>
                                <FontAwesomeIcon icon="trash-can" size="lg" onClick={() => this.row_del(crow) } />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            </div>
        </div>
        <div className="flex-row px-5">
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.cart_reset() }>RENSA</div>
        </div>
        <div className="mx_foot flex-col px-5 py-2">
            <div className="flex-row" py-2>
                <div className="txt_b18">Summa</div>
                <div className="flex"></div>
                <div className="txt_b18">{ utils.toPris( sbo.cart.belopp ) } kr</div>
           </div>
            {this.not_min() ?
                <div className="flex-row">
                    <div className="mfull-btn" onClick={() => this.wnd_close()}>MINI BELOPP EJ UPPNÅTT</div>
                </div>
                :
                <div className="flex-row">
                    <div className="mfull-btn" onClick={() => this.wnd_close()}>AVBRYT</div>
                    <div className="mfull-btn" onClick={() => this.cart_pay()}>BETALA</div>
                </div>
            }
        </div>
      </div>

        { this.state.wnds.cartpay ? <Foodle_Pay wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default Foodle_Cart;