import React, { useState, useEffect, useContext } from 'react';
import {Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'
import {withRouter} from "../lib/react/withRouter";

import * as utils from "../assets/utils";
import Tiden from "../lib/ui/tiden";

import '../cdi/css/cdi.css';
import sbo from '../cdi/sbo.js'

import Svepos_Home from './home.js';
import SveposLogo from "../cdi/img/logo-cdi.png";

import Web_Terms from './terms.js';
import Web_Privacy from './privacy.js';
import Web_Cdi from '../cdi/cdi.js';

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Home extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.wnds = {};
      var str = sessionStorage.getItem("sbo");
      this.state.sdo = JSON.parse(str);
      //alert(JSON.stringify(this.state.sdo));
      if(this.state.sdo) {
          if(this.state.sdo.cdo) sbo.cdo = this.state.sdo.cdo;
          if(this.state.sdo.cart) sbo.cart = this.state.sdo.cart;
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      //window.removeEventListener('scroll', this.wndScroll);
  }
  componentDidMount() {
      //window.addEventListener('scroll', this.wndScroll);
  }
    top_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        //if(this.state.top == 0) css.height = 35;
        return css;
    }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

    mnu_open(url) {
      //alert(url);
        this.props.navigate(url);
    }
  render() {

  return (
      <div className="web-app">

          <section className="web-top">
              <div className="svp-head">
                  <div className="flex-row">
                  <div className="py-3"><img src={SveposLogo} className="cursor-pointer svepos-top-logo" alt="Svepos"  onClick={() => this.fknGo("/") } /></div>
                      <div className="flex"></div>
                      <div className="px-2 txt-22" onClick={() => this.fknGo("/") }>CDI SYSTEM</div>
                  <div className="flex"></div>
                  <div className="px-2 txt-16">RETAIL AND HOSPITALITY SOLUTIONS</div>
                  </div>
              </div>
          </section>

          <div className="web-main">

          <div className="web-body">
              <Routes>
                  <Route path="terms" element={ <Web_Terms store={this.store} />} />
                  <Route path="privacy" element={ <Web_Privacy store={this.store} />} />
                  <Route path="*" element={ <Web_Cdi store={this.store} />} />
              </Routes>
          </div>

          <section className="web-foot">
              <div className="divider_orange"></div>
              <div className="flex-row flex py-5 svp-foot">
                  <div className="flex-col flex">
                      <div className="txt-16">CDI System AB</div>
                      <div className="txt-16">Energigatan 9</div>
                      <div className="txt-16">434 37 Kungsbacka</div>
                      <div className="txt-16">Sweden</div>
                  </div>
                  <div className="flex-col flex">
                      <div className="txt-16">www.cdisystem.com</div>
                      <div className="txt-16">Orgnr: 556573-8266</div>
                  </div>
                  <div className="flex-col flex">
                      <div className="txt-16">Kontakt</div>
                      <div className="txt-16">+46(0)10-750 50 00</div>
                      <div className="txt-16">info@cdisystem.com</div>
                  </div>
                  <div className="flex-col flex">
                      <div className="txt-16">Länkar</div>
                      <div className="txt-16 web-link" onClick={() => this.fknGo("/terms") }>Terms and Conditions</div>
                      <div className="txt-16 web-link" onClick={() => this.fknGo("/privacy") }>Privacy Policy</div>
                  </div>
              </div>
          </section>

          </div>
      </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Home));

/*
background-color: rgb(244, 59, 36);
*/