import React from 'react';
import {action, makeObservable, observable} from "mobx";

/* Store start */
export default class Store {
  title = "CDI / SVEPOS";
  lang = "sv";
  fkn = {};

  user = {
    userId: 1,
    name: "Teste Testsson",
    website: "https://www.cdihotel.com/",
    email: "joakim@cdihotel.com",
  };

  setFkn(fkn) {
    this.fkn = fkn;
  }
  setLang(lang) {
    this.lang = lang;
  }
  setUser(user) {
    this.user = user;
  }

  updateUser(data) {
    this.user = { ...this.user, ...data };
  }

  clearUser() {
    this.user = undefined;
  }

  setTitle(title) {
    this.title = title;
  }
}
/*
decorate(Store, {
  title: observable,
  lang: observable,
  fkn: observable,
  user: observable,
  setFkn: action,
  setLang: action,
  setUser: action,
  updateUser: action,
  clearUser: action,
  setTitle: action
});
*/

/* Store end */

/* Store helpers */
const StoreContext = React.createContext();

export const StoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);

/* HOC to inject store to any functional or class component */
export const withStore = (Component) => (props) => {
  return <Component {...props} xstore={useStore()} />;
};

export const SDOContext = React.createContext();

/*
export const SDOContext = React.createContext();

export const SDOProvider = SDOContext.Provider;
export const SDOConsumer = SDOContext.Consumer;

export default SDOContext;
*/