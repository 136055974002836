import React, { useState, useEffect, useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as net from "../lib/net";
import * as utils from "../assets/utils";

import Dt_Cal from "../lib/ui/dt_cal";
import Td_Sel from "../lib/ui/td_sel";

import '../css/App.css';

class Order_Menu extends React.Component {
  constructor(props, context) {
    super(props);

    this.selitems = [
      {fkn: 'open', text: 'ÖPPNA'},
      {fkn: 'cancel', text: 'AVBOKA'}
    ];

    this.store = props.store;
    this.state = {};
    this.state.seldate = new Date();
    this.state.avd = "01";
    this.state.kro = {};
    this.state.mna = [];
    this.state.cols = {};
    this.state.boknr = "";
    this.state.wnds = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.rvp();
  }

  prod_init() {
    var mna = [];
    var mno = {};

    mno = {};
    mno.plu = "1";
    mno.text = "Test Produkt";
    mno.price = "2700";
    mno.desc = "Beskrivning";
    mna.push( mno );

    mno = {};
    mno.plu = "1";
    mno.text = "Test Produkt";
    mno.price = "2700";
    mno.desc = "Beskrivning";
    mna.push( mno );

    mno = {};
    mno.plu = "1";
    mno.text = "Test Produkt";
    mno.price = "2700";
    mno.desc = "Beskrivning";
    mna.push( mno );

    this.setState({ mna: mna });
  }

  sel_row_fkn(row, sel) {
    //alert(JSON.stringify(fkn));
    if(sel.fkn === "open") {
      this.rv_edit(row);
    }
    if(sel.fkn === "cancel") {
      this.rvp_cancel(row.resv00.resno);
    }
  }

  body_style() {
    var css = {};
    css.height = window.innerHeight - 280;
    return css;
  }
  tb_style() {
    var css = {};
    css.height = window.innerHeight - 275;
    return css;
  }
  onUnitChange(e) {
    this.setState({unit: e.value});
  }

  rv_edit(row) {
    var self = this;
    var wnds = {};
    wnds.rvo = 1;
    wnds.boknr = row.resv00.resno;
    wnds.cb = this.cb_rv;
    this.setState({wnds: wnds});
  }
  cb_rv(ctx, ret) {
    //alert(JSON.stringify(ret));
    if(ret && (ret.ok == "000")) {
      ctx.rvp();
      ctx.setState({ wnds: {} });
    }
    else ctx.setState({wnds: {}});
  }
  fkn_close() {
    this.setState({edit: false });
    //this.props.history.push("cas");
    //this.login_srv(uid, pwd);
  }
  rvp_cancel(boknr) {
    var self = this;
    var prm = {};
    prm.req = "pms.rv_fkn.py_cancel";
    prm.boknr = boknr;

    net.sio_req(prm)
        .then(function(ret){
          //alert(JSON.stringify(ret.rca));

          self.rvp();
        })
        .catch(function(e) {
          var txt = JSON.stringify(e);
        });
  }

  rvp() {
    var self = this;
    var prm = {};
    prm.req = "pms.rv_fkn.rv_pys";

    net.sio_req(prm)
        .then(function(ret){
          //alert(JSON.stringify(ret.rca));

          self.setState({rva: ret.rco });
          self.syncTb("rvpTable");
        })
        .catch(function(e) {
          var txt = JSON.stringify(e);
        });
  }
  syncTb(tb) {
    if (!document.getElementById(tb)) {
      //alert("No tb");
      return;
    }
    var tbel = document.getElementById(tb);
    const thElements = tbel.getElementsByTagName('th'),
        tdElements = tbel.getElementsByTagName('td');

    for (let i = 0; i < thElements.length; i++) {
      const widerElement =
              thElements[i].offsetWidth > tdElements[i].offsetWidth ? thElements[i] : tdElements[i],
          width = window.getComputedStyle(widerElement).width;
      thElements[i].style.width = tdElements[i].style.width = width;
    }
  }

  render() {
    return (
        <div className="web-app">

          <div className="flex-row">
            <div className=""></div>
          </div>

          <div className="flex-row flex-space-between">
            <button className="btn btn-hw-blue w150 ripple" onClick={() => this.prod_init() } aria-label="System" >
              Visa
            </button>
          </div>
          <div className="divider_orange"></div>

          <div className="web-container flex-row">
            <div className="flex flex-col px-3">
            { this.state.mna.map((row, key) =>
                <div key={key} onDoubleClick={() => this.prd_edit(row) } className="orow flex-row">
                  <div className="px-2">{ row.plu }</div>
                  <div className="flex flex-col">
                    <div className="flex-row">
                    <div className="flex">{ row.text }</div>
                    <div className="text-right">{ utils.toPris( row.price ) }</div>
                  </div>
                  <div className="flex-row">
                    <div className="odesc">{ row.desc }</div>
                  </div>
                  </div>
                </div>
            )}
          </div>
          <div className="flex flex-col px-3">
            { this.state.mna.map((row, key) =>
                <div key={key} onDoubleClick={() => this.prd_edit(row) } className="orow flex-row">
                  <div className="flex">{ row.plu }</div>
                  <div className="flex">{ row.text }</div>
                  <div className="flex">{ utils.toPris( row.price ) }</div>
                </div>
            )}
          </div>
          </div>
        </div>
    );
  }
}
export default Order_Menu;

/*
          { this.state.wnds.rvo ?
              : null }
  <Pms_Rvo wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/>
*/