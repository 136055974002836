import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'

import sbo from './sbo.js'
import * as utils from "../../assets/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClock } from '@fortawesome/free-regular-svg-icons'
import Box_Sel from "../../lib/ui/box_sel";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Foodle_Delivery extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.loco = {};
      this.state.zip = "";
      this.state.adress = sbo.cdo.street;
      this.state.levsel = "";
      this.state.dlsel = "";
      this.state.levdt = "20220405";
      this.state.levdts = [
          {dts: '20220405', text: '2022-04-05'},
          {dts: '20220406', text: '2022-04-06'},
          {dts: '20220407', text: '2022-04-07'},
          {dts: '20220408', text: '2022-04-08'},
          {dts: '20220409', text: '2022-04-09'},
          {dts: '20220410', text: '2022-04-10'},
          {dts: '20220411', text: '2022-04-11'},
          {dts: '20220412', text: '2022-04-12'},
      ];

      this.state.unit = {};
      this.state.unit.city = "Nyköping";
      this.state.unit.adress = "Sankt Annegatan 2, Nyköping län Nyköping 611 34 Sweden";

      this.state.mga = [
          {id: 'dan', sid: 'S0000281', text: 'FOODLE DANDERYD', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'sic', sid: 'S0000283', text: 'FOODLE SICKLA', img: '', txtimg: '', class: 'mg-burger', desc: '' },
          {id: 'nyk', sid: 'S0000282', text: 'FOODLE NYKÖPING', img: '', txtimg: '', class: 'mg-sallad', desc: '' }
      ];
      this.state.dlt = [
          {id: '1', ftid: '1300', ttid: '1330', text: '' },
          {id: '2', ftid: '1330', ttid: '1400', text: '' },
          {id: '3', ftid: '1400', ttid: '1430', text: '' },
          {id: '4', ftid: '1430', ttid: '1500', text: '' },
          {id: '5', ftid: '1500', ttid: '1530', text: '' },
          {id: '6', ftid: '1530', ttid: '1600', text: '' },
          {id: '7', ftid: '1600', ttid: '1630', text: '' },
          {id: '8', ftid: '1630', ttid: '1700', text: '' },
          {id: '9', ftid: '1700', ttid: '1730', text: '' },
          {id: '10', ftid: '1730', ttid: '1800', text: '' },
          {id: '11', ftid: '1800', ttid: '1830', text: '' },
          {id: '12', ftid: '1830', ttid: '1900', text: '' },
          {id: '13', ftid: '1900', ttid: '1930', text: '' },
          {id: '14', ftid: '1930', ttid: '2000', text: '' }
      ];
      this.state.selitems = [
          {fkn: 'home', text: 'HEM'},
          {fkn: 'setup', text: 'INSTÄLLNINGAR'},
          {fkn: 'upd', text: 'UPPDATERA'},
          {fkn: 'sys', text: 'SYSTEM'},
          {fkn: 'logout', text: 'LOGGA UT'}
      ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {


    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
      this.my_loc();
/*
      if ("geolocation" in navigator) {
          alert("Available");
      } else {
          alert("Not Available");
      }
      navigator.geolocation.getCurrentPosition(function(position) {
          alert(JSON.stringify(position));
          //alert("Latitude is :", position.coords.latitude);
          //alert("Longitude is :", position.coords.longitude);
      }, function(error) {
          alert("Error Code = " + error.code + " - " + error.message);
      });
*/
  }

    my_loc() {
        var self = this;
        fetch("http://ip-api.com/json")
        .then(response => response.json())
        .then(
            (rsp) => {
                //alert(JSON.stringify(rsp));
                //alert("RSP: " + JSON.stringify(rsp));
                var loco = {};
                loco.city = rsp.city;
                loco.zip = rsp.zip;
                //alert(JSON.stringify(loco));
                self.setState({ loco: loco });
            },
            (error) => {
                //alert("Err: " + JSON.stringify(error));
            }
        );
        return;
    }
    my_location() {
        var cls = "";
        var options = {
            enableHighAccuracy: true,
            timeout: 1000,
            maximumAge: 0
        };
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.cb_succces, this.cb_fail, options);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
        return cls;
    }
    cb_succces = function(position) {
        alert(JSON.stringify(position));
    }
    cb_fail = function(error) {
        alert(JSON.stringify(error));
    }
    mg_cls(btn) {
        var cls = "flex col";
        //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
        //else cls = cls + " btn_free";
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }
    lev_style(typ) {
        var css = {};
        if(typ == this.state.levsel) {
            css.border = "1px solid #000";
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    lev_ico_style(typ) {
        var css = {};
        if(typ == this.state.levsel) css.color = "#fff";
        return css;
    }
    dl_style(dlo) {
        var css = {};
        if(dlo.ftid == this.state.dlsel) {
            css.border = "1px solid #000";
            css.background = "#080";
            css.color = "#fff";
        }
        return css;
    }
    dl_ico_style(dlo) {
        var css = {};
        if(dlo.ftid == this.state.dlsel) css.color = "#fff";
        return css;
    }
    unit_style() {
        var css = {};
        if(this.state.levsel !== "pickup") {
            css.display = "none";
        }
        return css;
    }
    bg_style() {
        var css = {};
        //css.margin = "0px auto";
        //css.padding = "100px 20px";
        //css.width = "100%";
        css.background = "#fee260";
        //css.backgroundImage = 'url("http://filpub.svepos.se/img/foodle/foodle_hero_startsida.jpeg")';
        //css.backgroundSize = "cover";
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      var url = "";
      url = "http://filpub.svepos.se/img/foodle/";
      url = url + fil;
      return url;
    }

    unit_open(mgo) {
        //sbo.cdo.unit = mgo.id;
        sbo.cdo.sid = mgo.sid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/foodle/kitchens";
        this.props.history.push(url);
    };
    unit_zip() {
        var sid = "";
        var zip = this.state.zip;
        //alert(zip);

        if(zip && (zip.substr(0, 2) == "11") ) {
            sid = "S0000281";
        }
        else if(zip && (zip.substr(0, 3) == "611") ) {
            sid = "S0000282";
        }
        else {
            alert("Ej aktivt postnummer");
            return;
        }
        //sbo.cdo.unit = mgo.id;
        sbo.cdo.zip = zip;
        sbo.cdo.sid = sid;
        var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        url = url + "/foodle/kitchens";
        this.props.history.push(url);
    };
    setAdress(e) {
        //alert(e.currentTarget.value);
        this.setState({ adress: e.currentTarget.value});
    };
    lev_sel(typ) {
        //alert(e.currentTarget.value);
        this.setState({ levsel: typ });
    };
    lev_dt(e) {
        //alert(JSON.stringify(e.dts));
        this.setState({ levdt: e.dts });
    };
    dl_sel(dlo) {
        //alert(e.currentTarget.value);
        sbo.cdo.slot = this.state.levdt + dlo.ftid;
        this.setState({ dlsel: dlo.ftid });
    };
    kassa_open() {
        var levtyp = this.state.levsel;
        var slot = this.state.dlsel;
        if(levtyp.length < 1) {
            alert("Välj leveranstyp");
            return;
        }
        if(slot.length < 1) {
            alert("Välj slot");
            return;
        }
        sbo.cart.levtyp = levtyp;
        sbo.cart.slot = slot;
        if(true) {
            var url = "/foodle/kassa";
            this.props.history.push(url);
            return;
        }
    }
    back() {
        if(true) {
            var url = "/foodle/";
            this.props.history.push(url);
            return;
        }
    }

    sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "home") {
        }
    }
  render() {

    return (
    <div className="web-app">

        <div className="wp flex-row  px-5 py-3">
            <div className="flex">
            <div className="txt_18 cursor-pointer" onClick={() => this.back() }><FontAwesomeIcon icon="arrow-left" size="1x" /> Tillbaka</div>
            </div>
            <div className="txt_22">LEVERANSUPPGIFTER</div>
            <div className="flex"></div>
        </div>

        <div className="" style={ this.bg_style() }>
            <div className="dlt-body">
                <div className="flex-col py-5">

                    <div className="flex-row">
                        <div className="txt_18">Din Adress</div>
                    </div>
                    <div className="flex-row">
                        <input className="foodle-input flex" value={ this.state.adress } onChange={ (e) => this.setAdress(e) } />
                    </div>

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>

                <div className="flex-row">
                    <div className="txt_18">LEVERANSSÄTT</div>
                </div>
                <div className="darkgrey dlt-grid">
                    <div className="foodle-box flex-row" style={ this.lev_style("deliver") } onClick={() => this.lev_sel("deliver") }>
                        <div className="txt_18"><FontAwesomeIcon icon="house" size="1x" /> Hemleverans</div>
                        <div className="flex"></div>
                        <div className="txt_18" style={ this.lev_ico_style("deliver") }><FontAwesomeIcon icon="circle-check" size="1x" /></div>
                    </div>
                    <div className="foodle-box flex-row" style={ this.lev_style("pickup") } onClick={() => this.lev_sel("pickup") }>
                        <div className="txt_18"><FontAwesomeIcon icon="gift" size="1x" /> Avhämtning</div>
                        <div className="flex"></div>
                        <div className="txt_18" style={ this.lev_ico_style("pickup") }><FontAwesomeIcon icon="circle-check" size="1x" /></div>
                    </div>

                    <Box_Sel items={ this.state.levdts } ctrltext={ utils.toDate( this.state.levdt ) } icon="calendar" cls="foodle-box" getValue={(e)=> this.lev_dt(e)} />
                </div>

                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>

                    <div className="flex-col" style={ this.unit_style() }>
                        <div className="lev-box flex-col">
                            <div className="fbold">{ this.state.unit.city }</div>
                            <div className="">{ this.state.unit.adress }</div>
                        </div>
                        <div className="flex-row py-3">
                            <div className="flex"></div>
                        </div>
                    </div>

                    <div className="flex-row">
                    <div className="txt_18">VÄLJ LEVERANSTID</div>
                </div>
                <div className="dlt-grid darkgrey">
                { this.state.dlt.map((dlo, key) =>
                    <div key={key} className="foodle-box flex-row flex" style={ this.dl_style(dlo) } onClick={() => this.dl_sel(dlo) } >
                        <div className="txt_18"><FontAwesomeIcon icon={faClock} size="1x" /> { utils.toTid(dlo.ftid) } - { utils.toTid(dlo.ttid) }</div>
                        <div className="flex"></div>
                        <div>Ledig</div>
                    </div>
                )}
                </div>
                    <div className="flex-row py-3">
                        <div className="flex"></div>
                    </div>
                    <div className="flex-row">
                        <div className="mpay-btn" onClick={() => this.kassa_open() }>GÅ TILL KASSAN</div>
                    </div>

                </div>
            </div>

        </div>
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Foodle_Delivery);
