import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import * as srs from "../../../lib/srs";
import * as utils from "../../../assets/utils";
import Tiden from "../../../lib/ui/tiden";
import sbo from '../sbo.js'

import Plu_Open from "./plu_open";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Menu_Grp extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.wnds = {};

      this.state.selix = 0;
      this.state.gid = "001";
      if(sbo.cdo && sbo.cdo.gid) this.state.gid = sbo.cdo.gid;

      this.state.mga = [
          {gid: '001', id: 'burgers', text: 'TASTY BURGERS', class: 'mgb-burgers', img: 'txtburger.jpeg' },
          {gid: '002', id: 'pizza', text: 'BELLA PIZZA', class: 'mgb-pizza', img: 'txtpizza.png' },
          {gid: '003', id: 'pasta', text: 'LA PASTA', class: 'mgb-pasta', img: 'txtpasta.png' },
          {gid: '004', id: 'sushi', text: 'SASSY SUSHI', class: 'mgb-sushi', img: 'txtsushi.png' },
          {gid: '005', id: 'taco', text: 'TACO LOCO', class: 'mgb-taco', img: 'txttaco.png' },
          {gid: '006', id: 'kebab', text: 'PASHA KEBAB', class: 'mgb-kebab', img: 'txtkebab.png' },
          {gid: '007', id: 'wok', text: 'WICKED WOK', class: 'mgb-wok', img: 'txtwok.png' },
          {gid: '008', id: 'sallad', text: 'LEAN GREEN', class: 'mgb-sallad', img: 'txtleangreen.png' },
          {gid: '009', id: 'husman', text: 'HUSMAN', class: 'mgb-husman', img: 'txtburger.jpeg' }
      ];
      this.state.mgo = {};
      this.state.mpa = [];
      this.state.pro = {};

      this.state.mscroll = 0;
      this.state.pos = { top: 0, left: 0, x: 0, y: 0 };

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
    //this.dsi_mp(this.state.gid);
      this.dsi_mg();
      //this.mg_open(this.state.mga[0]);
  }

    scroll_init() {
        //const ele = document.getElementById('mnu-container');
        //ele.scrollTop = 100;
        //ele.scrollLeft = 250;
    }
    handleMouseDown(e) {
        const ele = document.getElementById('mnu-container');
        var pos = this.state.pos;
        pos.x = e.clientX;
        pos.y = e.clientY;
        pos.left = ele.scrollLeft;
        pos.top = ele.scrollTop;
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';

        this.setState({ pos: pos, mscroll: 1 });
    }
    handleMouseUp(e) {
        const ele = document.getElementById('mnu-container');
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
        this.setState({ mscroll: 0 });
    }
    handleMouseMove(e) {
      if(!this.state.mscroll) return;
        const ele = document.getElementById('mnu-container');
        var pos = this.state.pos;
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
        //this.setState({ pos: pos });
    }

    mpa_get() {
        var mpa = [];
        var gid = this.state.gid;

    }

    mg_cls() {
        var cls = "flex-row";
        if(this.state.mgo) cls = cls + " " + this.state.mgo.class;
/*
        if(this.state.gid == "001") cls = cls + " mgb-burgers";
        if(this.state.gid == "002") cls = cls + " mgb-pizza";
        if(this.state.gid == "003") cls = cls + " mgb-pasta";
        if(this.state.gid == "004") cls = cls + " mgb-sushi";
        if(this.state.gid == "005") cls = cls + " mgb-taco";
        if(this.state.gid == "006") cls = cls + " mgb-kebab";
        if(this.state.gid == "007") cls = cls + " mgb-wok";
        if(this.state.gid == "008") cls = cls + " mgb-sallad";
        if(this.state.gid == "009") cls = cls + " mgb-husman";
*/
        return cls;
    }
    mg_style(btn) {
        var css = {};
        //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
        //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
        return css;
    }
    mgtop_style(mgo) {
        var css = {};
        if(mgo.gid == this.state.gid) {
            css.background = "#fff";
            css.color = "#080";
            css.borderBottom = " 2px solid #080";
        }
        return css;
    }
    mg_img() {
        var img = "";
        if(this.state.mgo) img = this.state.mgo.img;

/*        if(this.state.gid == "001") img = "txtburger.jpeg";
        if(this.state.gid == "002") img = "txtpizza.png";
        if(this.state.gid == "003") img = "txtpasta.png";
        if(this.state.gid == "004") img = "txtsushi.png";
        if(this.state.gid == "005") img = "txttaco.png";
        if(this.state.gid == "006") img = "txtkebab.png";
        if(this.state.gid == "007") img = "txtwok.png";
        if(this.state.gid == "008") img = "txtleangreen.png";
        if(this.state.gid == "009") img = "txtburger.jpeg";
*/
        return img;
    }

    fknGo(url) {
        //props.history.push(row.url);
        this.props.history.push(url);
    };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    imgUrl(fil) {
      var url = "";
      url = "http://filpub.svepos.se/img/foodle/";
      url = url + fil;
      return url;
    }

    mg_prev() {
        var ix = 0;
        ix = (this.state.selix * 1);
        ix = ix - 1;
        if(ix < 0) ix = 0;
        if(!this.state.mga[ix]) return;
        var mgo = this.state.mga[ix];
        this.mg_open(mgo);
    };
    mg_next() {
        var ix = 0;
        ix = (this.state.selix * 1);
        ix = ix + 1;
        if(ix < 0) ix = 0;
        if(!this.state.mga[ix]) return;
        var mgo = this.state.mga[ix];
        this.mg_open(mgo);
    };
    mg_open(mgo) {
        //var url = "";
        //url = url + "/foodle/menu/" + mgo.id;
        //this.props.history.push(url);
        //this.setState({ gid: mgo.gid });
        var ix = this.state.mga.indexOf(mgo);
        this.setState({ gid: mgo.gid, mgo: mgo, selix: ix });
        this.dsi_mp(mgo.gid);
    };
    plu_sel(row) {
        var self = this;
        var wnds = {};
        wnds.pluopen = true;
        wnds.gid = this.state.gid;
        wnds.pbo = row;
        this.setState({ wnds: wnds });
    }
    dsi_mg() {
        var self = this;

        this.setState({ mga: [] });

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = "S0000091";
        prm.req = "dsi.dsi_ecom.ecom_chan";
        //prm.mnuid = gid;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
            .then(function(ret){
                //alert(JSON.stringify(ret));

                var mga = ret.rca;
                //var mgo = mga[0];
                var mgo = mga.filter(row => {
                    return row.gid === self.state.gid;
                })
                var ix = 0;
                //var mgo = null;
                var mgo = mga[ix];
                var keys = Object.keys(mga);
                for(var key of keys) {
                    var row = mga[key];
                    if(row.gid == self.state.gid) {
                        ix = key;
                        mgo = row;
                    }
                }
                //var ix = mga.indexOf(mgo);
                //alert(JSON.stringify(mgo));
                self.setState({ mga: mga, gid: mgo.gid, mgo: mgo, selix: ix }, function() {
                    self.scroll_init();
                    self.dsi_mp(mgo.gid);
                });
                //self.syncTb("mnaTable");
                //self.mg_open(mgo);
            })
            .catch(function(e){
                var txt = JSON.stringify(e);
            });
    }
    dsi_mp(gid) {
        var self = this;

        this.setState({ gid: gid, mpa: [] });

        var prm = {};
        //prm.sid = sbo.cdo.sid;
        prm.sid = "S0000091";
        prm.req = "dsi.dsi_mp.mp_web";
        prm.mnuid = gid;

        //alert(JSON.stringify(prm));
        srs.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            self.setState({ mpa: ret.rca });
            //self.syncTb("mnaTable");
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
    }

    nrof_style(row) {
        var css = {};
       if(!row.nrof || (row.nrof < 1)) {
            css.display = "none";
        }
        return css;
    }
    mpo_style(row) {
        var css = {};
        css.color = "#ddd";
        css.fontSize = "26px";
        if(this.state.pro[row.plu]) {
            css.color = "#fee260";
        }
        return css;
    }
    tbo_add(row, typ) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(!row.nrof) row.nrof = 0;
        row.nrof = row.nrof + 1;
        //alert(row.m0nrof);
        belopp = belopp + (row.price * 1);
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    tbo_sub(row, typ) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;

        if(!row.nrof) row.nrof = 0;
        else row.nrof = row.nrof - 1;
        //alert(row.m0nrof);

        if(row.nrof < 1) {
            delete( pro[row.plu] );
            row.nrof = 0;
        }
        belopp = belopp - (row.price * 1);
        //belopp = belopp - (row.m0pris * 1);
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }
    pro_icon(row) {
        var ico = "";
        ico = "circle";
        if(this.state.pro[row.plu]) {
            ico = "circle-check";
        }
        return ico;
    }
    pro_style(row) {
        var css = {};
        css.color = "#ddd";
        css.fontSize = "26px";
        if(this.state.pro[row.plu]) {
            css.color = "#fee260";
        }
        return css;
    }
    pro_set(row) {
        var pro = this.state.pro;
        var belopp = pro.belopp * 1;
        if(pro[row.plu]) {
            delete( pro[row.plu] );
            belopp = belopp - ((row.price * 1) * (row.nrof * 1));
            row.nrof = 0;
        }
        else {
            pro[row.plu] = row;
            row.nrof = 1;
            belopp = belopp + (row.price * 1);
        }
        pro.belopp = belopp.toString();
        this.setState({ pro: pro });
    }

    cart_add(mpo) {
        var self = this;

        //var pro = this.state.pro;
        mpo.belopp = mpo.price * mpo.nrof;
        var row = {};
        row.plu = mpo.plu;
        row.text = mpo.text;
        row.antal = "1";
        row.pris = mpo.price;
        row.belopp = mpo.belopp;
        row.taxproc = "2000";
        row.pro = mpo;

        if(!sbo.cart.belopp) sbo.cart.belopp = 0;
        if(!sbo.cart.rows) sbo.cart.rows = [];
        sbo.cart.rows.push(row);
        var belopp = sbo.cart.belopp * 1;
        belopp = belopp + (mpo.belopp * 1);
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }

  render() {

    return (
    <div className="web-section">

        <div id="mnu-container" className="flex-row mp-top-grid" onMouseDown={(ev)=> this.handleMouseDown(ev)} onMouseUp={(ev)=> this.handleMouseUp(ev)} onMouseMove={(ev)=> this.handleMouseMove(ev)} >
            { this.state.mga.map((mgo, key) =>
                <div className="mp-top-item noselect" style={ this.mgtop_style(mgo) } onClick={() => this.mg_open(mgo) }>{ mgo.text }</div>
            )}
        </div>

        <div className="mp-center-left" >
            <FontAwesomeIcon className="foodle_circle" icon="circle-arrow-left" size="5x" onClick={() => this.mg_prev() } />
        </div>
        <div className="mp-center-right" >
            <FontAwesomeIcon className="foodle_circle" icon="circle-arrow-right" size="5x" onClick={() => this.mg_next() } />
        </div>
        <div className="mp-center-right hide" >
            <FontAwesomeIcon className="ico-gray" icon="circle-arrow-right" size="4x" onClick={() => this.mg_next() } />
        </div>

        <div className={this.mg_cls()}>


            <div className="mg-body">
                <div className="" >
                    <div className="text-center noselect" >
                        <img src={this.imgUrl(this.mg_img())} className="foodle-img" alt="Foodle" />
                    </div>

                    { this.state.mpa.map((mpo, key) =>
                    <div key={key} className="row plurow" >
                        { (this.state.gid == "019") || (this.state.gid == "041") ?
                            <div className="" >
                                    <div className="flex-row" >
                                        <div className="px-3">
                                            <FontAwesomeIcon icon={ this.pro_icon(mpo) } size="3x" style={ this.pro_style(mpo) } onClick={() => this.pro_set(mpo) } />
                                        </div>
                                        <div className="plutxt" onClick={() => this.pro_set(mpo) }>{ utils.toLang(mpo.text) }</div>
                                        <div className="flex" onClick={() => this.pro_set(mpo) }></div>

                                        <div className="flex-row px-2" style={ this.nrof_style(mpo) }>
                                            <FontAwesomeIcon icon="circle-minus" style={ this.mpo_style(mpo) } onClick={() => this.tbo_sub(mpo, "D") } />
                                            <div className="txt_18 px-1">{ utils.toNum( mpo.nrof ) }</div>
                                            <FontAwesomeIcon icon="circle-plus" style={ this.mpo_style(mpo) } onClick={() => this.tbo_add(mpo, "D") } />
                                        </div>

                                        <div className="plutxt txt_24" onClick={() => this.pro_set(mpo) }>{ utils.toPris(mpo.price) } kr</div>
                                    </div>
                                    <div className="flex-row" onClick={() => this.pro_set(mpo) }>
                                        <div className="px-4">
                                        </div>
                                        <div className="pludesc txtwrap">{ utils.toLang(mpo.desc) }</div>
                                    </div>
                            </div>
                            :
                            <div className="" >
                            <div className="flex-row" >
                                <div className="plutxt">{ utils.toLang(mpo.text) }</div>
                            <div className="flex"></div>

                            <div className="flex-row px-2" style={ this.nrof_style(mpo) }>
                                <FontAwesomeIcon icon="circle-minus" style={ this.mpo_style(mpo) } onClick={() => this.tbo_sub(mpo, "D") } />
                                <div className="txt_18 px-1">{ utils.toNum( mpo.nrof ) }</div>
                                <FontAwesomeIcon icon="circle-plus" style={ this.mpo_style(mpo) } onClick={() => this.tbo_add(mpo, "D") } />
                            </div>

                            <div className="plutxt txt_24">{ utils.toPris(mpo.price) } kr</div>
                        </div>
                        <div className="flex-col" >
                            <div className="pludesc txtwrap">{ utils.toLang(mpo.desc) }</div>
                        </div>
                        <div className="flex-row" >
                            <div className="flex"></div>
                            <div className="mp-btn" onClick={() => this.plu_sel(mpo) }>LÄGG TILL</div>
                        </div>
                        </div>
                        }
                    </div>
                )}
            </div>
                <div className="h80"></div>

            </div>
        </div>
        { this.state.wnds.pluopen ? <Plu_Open wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Menu_Grp);

/*
        <img src={this.imgUrl("burgers_top.png")} className="mg-top" alt="Foodle" />

*/