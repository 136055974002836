import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

//import logo from './logo.svg';
import './css/wsa.css';

import pg_bo from './bo/bo.js';

import Web_Home from './web/home.js';
import Web_Login from './web/login.js';
import Web_Tst from './tst/tst.js';

import Web_Hello from './web/hello.js';
import Sys_bo from './bo/bo.js';

import Sub_std from './web/std.js';
import Sub_Order from './order/order.js';

import Sub_Cdi from './cdi/cdi.js';
import Sub_Visit from './cdi/visit.js';
import Sub_Massa from './cdi/massa.js';
import Sub_Foodle from './kund/foodle/foodle.js';

import Store, { StoreProvider } from "./sdo";
import WebStore from "./ado";

const webStore = new WebStore();
const store = new Store();

function WsaApp() {
    //var sdo = useContext(SDO);

    var url = window.location.href;
    //var foodUrl = url.match(/foodle/g);
    //if(!foodUrl) window.location = "/foodle";
    var massUrl = url.match(/massa/g);
    //if(!massUrl) window.location = "/massa";

//    fetch('/cfg')
//    .then(rsp => alert(JSON.stringify(rsp)));
//  .then(response => response.json())
//  .then(data => this.setState({ data }));

    const[txt, setTxt] = useState("SS00");
    var url = window.location.href;
    var tstUrl = url.match(/test.cdihotel.com/g);
    //var tstUrl = url.match(/localhost/g);
    //tstUrl = true;

    var xdo = sessionStorage.getItem("svp");
    //alert(xdo);
    webStore.cfg.brand = 'svk';
    webStore.cfg.logo = 'svk';
    //webStore.cfg.logo = 'cdi';
    if(tstUrl) {
        webStore.cfg.brand = 'cdi';
        webStore.cfg.logo = 'cdi';
    }

    //if(!xdo) {
    if(false) {
        webStore.seo = {};
        webStore.seo.loggedin = false;
       var html =
        <BrowserRouter forceRefresh={false}>
            <Routes>
                <Route path="/bo/*" element={ <Sys_bo store={webStore} />} />
                <Route path="*" forceRefresh={true} element={ <Web_Login store={webStore} />} />
            </Routes>
        </BrowserRouter>
        return html;
    }

    if(xdo) {
        var seo = JSON.parse(xdo);
        webStore.seo = seo;
        //return routefkn(sdo);
    }
    //alert(JSON.stringify(sdo));

    webStore.cfg.mnu_show = true;
    webStore.cfg.bread = false;
    webStore.cfg.bread = true;

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    webStore.pos = pos;

    var pms = {};
    pms.rts = [];
    pms.pkats = [];
    pms.stat = {};
    webStore.pms = pms;

    var ta = {};
    webStore.ta = ta;

    var html =
    <StoreProvider store={store}>
    <BrowserRouter forceRefresh={false}>
        <Routes>
            <Route exact path="/admin" component={pg_bo} />

            <Route path="/bo" render={(props) => <Sys_bo {...props} store={webStore} />} />

            <Route path="/bo" element={ <Sys_bo store={webStore} />} />
            <Route path="/foodle" element={ <Sub_Foodle store={webStore} />} />

            <Route path="/std" element={ <Sub_std store={webStore} />} />
            <Route path="/order" element={ <Sub_Order store={webStore} />} />

            <Route path="/hello" element={ <Web_Hello store={webStore} />} />
            <Route path="/tst" element={ <Web_Tst store={webStore} />} />

            <Route path="/home/*" element={ <Web_Home store={webStore} />} />
            <Route path="/cdi/*" element={ <Sub_Cdi store={webStore} />} />
            <Route path="/visit/*" element={ <Sub_Visit store={webStore} />} />
            <Route path="/massa/*" element={ <Sub_Massa store={webStore} />} />
            <Route path="*" forceRefresh={true} element={ <Web_Home store={webStore} />} />
        </Routes>
    </BrowserRouter>
    </StoreProvider>
    ;
    return html;
}

export default WsaApp;
