import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
//import TagManager from 'react-gtm-module'

import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import './css/flex.css';
import './css/lt.css';
import './css/mdlg.css';

import './assets/string.js';

import WsaApp from './wsa';
import reportWebVitals from './reportWebVitals';
import { library } from '@fortawesome/fontawesome-svg-core'
//import { far } from '@fortawesome/free-regular-svg-icons'
//import { fas } from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, fab);

/*
var gtmid_foodle = "GTM-MDLLJLC";
const tagManagerArgs = {
    gtmId: gtmid_foodle
}
TagManager.initialize(tagManagerArgs)
*/

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<WsaApp tab="home" />);

/*
ReactDOM.render(
  <React.StrictMode>
    <WsaApp />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
