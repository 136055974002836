import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {withRouter} from "../lib/react/withRouter";

import * as utils from "../assets/utils";
import Tiden from "../lib/ui/tiden";

import './css/cdi.css';
import sbo from './sbo.js'

import Svepos_Home from './home.js';
import SveposLogo from "./img/logo-cdi.png";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Svepos extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.wnds = {};
      var str = sessionStorage.getItem("sbo");
      this.state.sdo = JSON.parse(str);
      //alert(JSON.stringify(this.state.sdo));
      if(this.state.sdo) {
          if(this.state.sdo.cdo) sbo.cdo = this.state.sdo.cdo;
          if(this.state.sdo.cart) sbo.cart = this.state.sdo.cart;
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      //window.removeEventListener('scroll', this.wndScroll);
  }
  componentDidMount() {
      //window.addEventListener('scroll', this.wndScroll);
  }
    top_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        //if(this.state.top == 0) css.height = 35;
        return css;
    }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

    mnu_open(url) {
      //alert(url);
        this.props.navigate(url);
    }
  render() {

  return (
      <div className="web-app">

          <section className="cdi-head flex-col">
              <div className="flex-col py-5">
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <h1>CDI SYSTEM AB</h1>
                      </div>
                  </div>
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <h2>CUTTING EDGE SOFTWARE SOLUTIONS</h2>
                      </div>
                  </div>
              </div>
          </section>

          <section className="cdi-bg-01">
              <div className="cdi-body flex-col">
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <h1>CDI SYSTEM AB</h1>
                      </div>
                  </div>
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <div className="txt-16">Komplett system leverantör. Lösningar för alla branschers försäljning och betalnings behov.</div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="cdi-bg-02">
              <div className="cdi-body flex-col">
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <h1>BUTIKSYSTEM</h1>
                      </div>
                  </div>
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <div className="txt-16">Butiks lösningar med komplett kassasystem, tidsystem koppling till betalterminaler mm.</div>
                          <div className="txt-16">Online tjänster fullt integrerade med realtids uppföljning.</div>
                          <div className="txt-16">Kampanjsystem med lojalitet och rabatthantering.</div>
                          <div className="txt-16">Kontakta oss så berättar vi mer.</div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="cdi-bg-01">
              <div className="cdi-body flex-col">
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <h1>HOTELLSYSTEM</h1>
                      </div>
                  </div>
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <div className="txt-16">Komplett system lösning för hotell med bokningsystem, kassasystem, tidsystem, faktureringssystem mm </div>
                          <div className="txt-16">Interface till diverse kringsystem såsom låssystem, TV system, belysningssystem mm</div>
                          <div className="txt-16">Online reservation, koppling till boknings sajter mm.</div>
                          <div className="txt-16">Kontakta oss så berättar vi mer.</div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="cdi-bg-02">
              <div className="cdi-body flex-col">
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <h1>RESTAURANGSYSTEM</h1>
                      </div>
                  </div>
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <div className="txt-16">Komplett kassasystem för restaurang, nattklubb, fastfood mm</div>
                          <div className="txt-16">Terminaler för alla behov, tex: touchkassor, bärbara kassor, bärbara kassor inkl EMV terminal, self service kassor mm</div>
                          <div className="txt-16">Online försäljing och app försäljning via QR mm</div>
                          <div className="txt-16">KDS och MDS för köks och menystyrning.</div>
                          <div className="txt-16">Kontakta oss så berättar vi mer.</div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="cdi-bg-01">
              <div className="cdi-body flex-col">
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <h1>KAMPANJSYSTEM</h1>
                      </div>
                  </div>
                  <div className="flex-row">
                      <div className="flex-center flex">
                          <div className="txt-16">Kompletta kampanj och lojalitetslösningar</div>
                          <div className="txt-16">Online hantering av</div>
                          <div className="txt-16">KDS och MDS för köks och menystyrning.</div>
                          <div className="txt-16">Kontakta oss så berättar vi mer.</div>
                      </div>
                  </div>
              </div>
          </section>

      </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Svepos));

/*
background-color: rgb(244, 59, 36);
*/