import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { observer } from 'mobx-react'

import Tiden from "../lib/ui/tiden";
import sbo from "./sbo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as srs from "../lib/srs";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Svepos_Home extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
    this.state.zip = "";
    this.state.loco = {};

    this.state.mga = [
      {gid: '001', id: 'burgers', text: 'BURGERS', img: 'burgers.png', txtimg: 'txtburger.jpeg', class: 'mg-burger', desc: 'Du hör rätt! Vi gör galet goda burgare och schyssta sides.' },
    ];

    //this.state.lat = "57.5079875";
    //this.state.lon = "11.9407502";
    this.state.lat = "57.506280";
    this.state.lon = "12.059960";

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    this.props.store.pos = pos;
    //alert(JSON.stringify(pos));
    //this.geo_init();
    //this.get_adress();
    //this.dsi_ecom();
  }
  geo_init() {
    if ("geolocation" in navigator) {
      alert("Available");
    } else {
      alert("Not Available");
    }
    navigator.geolocation.getCurrentPosition(function(position) {
      alert(JSON.stringify(position));
      //alert("Latitude is :", position.coords.latitude);
      //alert("Longitude is :", position.coords.longitude);
    }, function(error) {
      alert("Error Code = " + error.code + " - " + error.message);
    });
  }

  get_adress() {
    var self = this;
    var apikey = "AIzaSyD9Ai4X2zbmpvgl_I8VAaSjkj9KFpe0aI8";
    var latlng = this.state.lat + "," + this.state.lon;
    var url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlng + "&key=" + apikey;
    alert(JSON.stringify(url));
    fetch(url)
        .then(
            (rsp) => {
              alert(JSON.stringify(rsp));
              //alert("RSP: " + JSON.stringify(rsp));
              var loco = {};
              loco.city = rsp.city;
              loco.zip = rsp.zip;
              //alert(JSON.stringify(loco));
              self.setState({ loco: loco });
            },
            (error) => {
              //alert("Err: " + JSON.stringify(error));
            }
        );
  }
  bg_full_style() {
    var css = {};
    css.height = window.innerHeight;
    return css;
  }
  bg_style() {
    var css = {};
    css.margin = "0px auto";
    //css.padding = "100px 20px";
    css.width = "100%";
    css.backgroundImage = 'url("http://filpub.svepos.se/img/foodle/foodle_hero_startsida.jpeg")';
    css.backgroundSize = "auto";
    //css.backgroundSize = "cover";
    //css.backgroundAttachment = "fixed";
    css.backgroundPosition = "-550px -100px";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  home_style() {
    var css = {};
    css.margin = "0px";
    //css.padding = "300px 225px";
    css.padding = "100px 25px";
    //css.width = "1024px";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }
  bg1_style() {
    var css = {};
    css.margin = "0px auto";
    css.padding = "0px";
    css.width = "100%";
    css.background = "rgb(207,226,248)";
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }

  fknGo(url) {
    //props.history.push(row.url);
    this.props.history.push(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }
  dsi_ecom() {
    var self = this;

    var prm = {};
    //prm.sid = sbo.cdo.sid;
    prm.sid = "S0000091";
    prm.req = "dsi.dsi_ecom.ecom_adress";
    prm.lat = this.state.lat;
    prm.lon = this.state.lon;

    //alert(JSON.stringify(prm));
    srs.sio_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        var zip = ret.zip;
        self.setState({ zip: zip });
        //self.syncTb("mnaTable");
      })
      .catch(function(e){
        var txt = JSON.stringify(e);
      });
  }
  unit_zip() {
    var sid = "";
    var zip = this.state.zip;
    //alert(zip);

    if(zip && (zip.substr(0, 2) == "11") ) {
      sid = "S0000281";
    }
    else if(zip && (zip.substr(0, 3) == "611") ) {
      sid = "S0000282";
    }
    else {
      sid = "S0000281";
      //alert("Ej aktivt postnummer");
      //return;
    }
    //sbo.cdo.unit = mgo.id;
    sbo.cdo.zip = zip;
    sbo.cdo.sid = sid;

    sessionStorage.setItem("sbo", JSON.stringify(sbo));

    var url = "";
    //url = url + "/foodle/menu/" + mgo.id;
    url = url + "/foodle/kitchens";
    this.props.history.push(url);
  };
  setZip(e) {
    //alert(e.currentTarget.value);
    this.setState({ zip: e.currentTarget.value});
  };

  mg_cls(btn) {
    var cls = "col";
    //if(btn.f0plu && (btn.f0plu.trim() != "")) cls = cls + " btn_pos";
    //else cls = cls + " btn_free";
    return cls;
  }
  mg_style(btn) {
    var css = {};
    //if(btn.f0kod) css.background = this.cdi_rgb(btn.f0kod);
    //if(btn.f0bkod) css.color = this.cdi_rgb(btn.f0bkod);
    return css;
  }

  imgUrl(fil) {
    var url = "";
    url = "http://filpub.svepos.se/img/foodle/";
    url = url + fil;
    return url;
  }

  mg_open(mgo) {
    sbo.cdo.gid = mgo.gid;
    var url = "";
    //url = url + "/foodle/menu/" + mgo.id;
    url = url + "/foodle/menu/grp";
    this.props.history.push(url);
  };
  faq() {
    return;
  }

  render() {

    return (
        <div className="web-app">

          <section className="bg_home_09" style={ this.bg_full_style() }>

            <div className="wp">
              <div className="h80"></div>
              <div className="flex-col py-5" style={ this.home_style() }>

                <div className=" py-1">
                <div className="lh120">SVERIGEKASSAN</div>
                <div className="lh120">GASTRO NORD</div>
              </div>
              </div>
            </div>

          </section>


        </div>
  );
}
}
//Sys.contextType = ADOContext;

export default observer(Svepos_Home);
