import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {withRouter} from "../lib/react/withRouter";

import * as utils from "../assets/utils";
import Tiden from "../lib/ui/tiden";

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Privacy extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
      this.state.top = 1;
      this.state.info = "";
      this.state.sid = "S1";
      this.state.wnds = {};
      var str = sessionStorage.getItem("sbo");
      this.state.sdo = JSON.parse(str);
      //alert(JSON.stringify(this.state.sdo));
      if(this.state.sdo) {
      }
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
      //window.removeEventListener('scroll', this.wndScroll);
  }
  componentDidMount() {
      //window.addEventListener('scroll', this.wndScroll);
  }
    top_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        //if(this.state.top == 0) css.height = 35;
        return css;
    }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

    mnu_open(url) {
        //alert(url);
        this.props.navigate(url);
    }
    slide(sid) {
        var css = {};
        css.display = "none";
        if(sid == this.state.sid) css.display = "";
        return css;
    }
    slide_step(sid) {
        var nid = "S1";
        if(sid == "S1") nid = "S2";
        if(sid == "S2") nid = "S3";
        if(sid == "S3") nid = "S1";
        this.setState({ sid : nid });
    }
    slide_tick() {
        var self = this;
        var sid = this.state.sid;
        var nid = sid;
        if(sid == "S1") nid = "S2";
        if(sid == "S2") nid = "S3";
        if(sid == "S3") nid = "S1";
        //alert("SID: " + nid);
        this.setState({ sid : nid }, function() {
            setTimeout(function () {
                self.slide_tick();
            }, 10000);
        });
    }
  render() {

  return (
      <div className="web-app">

          <div className="svp-body py-5">

              <h3>PERSONUPPGIFTSPOLICY</h3>

              Denna personuppgiftspolicy beskriver hur CDI System AB, org.nr. 556573-8266, (”CDI”) behandlar dina personuppgifter.
              CDI kommer i alla lägen se till att personuppgifter behandlas på ett lagenligt och korrekt sätt.
              Nedan finner du hur vi behandlar dina personuppgifter.
              Denna personuppgiftspolicy gäller endast för CDIs kontoinnehavare och kunder som använder våra onlinetjänster på cdisystem.com.

              <div className="svp-head">1 VEM ANSVARAR FÖR DINA PERSONUPPGIFTER?</div>
              CDI är personuppgiftsansvarig för dina personuppgifter och ansvarar för att sådan behandling sker i enlighet med gällande dataskyddsbestämmelser.

              <div className="svp-head">2 VILKA PERSONER BEHANDLAR VI PERSONUPPGIFTER OM?</div>
              Vi behandlar personuppgifter om följande kategorier av personer:
              Onlinekunder, dvs. den person som besöker och använder CDI’s onlinetjänster samt genomför köp på CDI webbplats.
              Kontoinnehavare, dvs. den person som innehar ett konto på cdisystem.com och använder CDIs onlinetjänster samt genomför köp på CDI’s webbplats.

              <div className="svp-head">3 VILKA PERSONUPPGIFTER BEHANDLAR VI OM DIG?</div>
              3.1 För dig som är Onlinekund till CDI.
              Vi kan komma att behandla följande personuppgifter om dig som Onlinekund:

              Namn,
              Kontaktuppgifter, såsom adress, e-postadress och telefonnummer, portkod
              IP-nummer och information om din användning av CDI’s webbplats,
              Information om dina beställningar samt
              Betalningsinformation och betalningshistorik
              3.2 För dig som är kontoinnehavare.
              Vi kan komma att behandla följande personuppgifter om dig som kontoinnehavare:

              Namn,
              Kontaktuppgifter, såsom adress, e-postadress och telefonnummer, portkod
              IP-nummer och information om din användning av CDI’s webbplats cdisystem.com,
              Information om ditt kontoinnehav och konto-ID,
              Information om dina beställningar och beställningshistorik samt
              Betalningsinformation och betalningshistorik.

              <div className="svp-head">4 VARFÖR OCH ENLIGT VILKEN RÄTTSLIG GRUND BEHANDLAR VI DINA PERSONUPPGIFTER?</div>
              4.1 För att kunna ingå och hantera avtal med dig som onlinekund eller kontoinnehavare inhämtar och behandlar CDI personuppgifter tillhörande dig. Den lagliga grunden för vår behandling av dina personuppgifter är att den är nödvändig för att fullgöra vårt avtal med dig eller för att vidta åtgärder innan ett sådant avtal ingås. Om du inte lämnar ovan nämnda personuppgifter har vi inte möjlighet att erbjuda våra tjänster, genomföra en beställning eller i övrigt fullgöra våra åtaganden gentemot dig.
              4.2 CDI inhämtar och behandlar även personuppgifter tillhörande dig som onlinekund eller kontoinnehavare i syfte att kvalitetssäkra vår verksamhet, att kontakta dig och erbjuda samt marknadsföra våra tjänster till dig. Vi kan också komma att skicka relevanta marknadsföringserbjudanden till dig i form av bland annat nyhetsbrev, kampanjerbjudanden via sociala medier, e-post och sms. Den lagliga grunden är att behandlingen är nödvändig för vårt berättigade intresse av att kunna utveckla vår verksamhet och möta dina behov, marknadsföra produkter och tjänster som vi tror att du kan vara intresserad av. Vårt intresse av att behandla dina personuppgifter för detta ändamål går före ditt eventuella intresse av att skydda din personliga integritet mot bakgrund av de potentiella fördelar som marknadsföringen medför för dig. Du har dock rätt att när som helst invända mot behandling av personuppgifter för direktmarknadsföring.
              4.3 Vissa personuppgifter kan också behandlas på grund av att CDI har en rättslig förpliktelse att fullfölja, exempelvis personuppgifter till följd av CDI’s bokföringsskyldighet eller andra skyldigheter som åvilar oss enligt lag.

              <div className="svp-head">5 HUR LÄNGE SPARAR VI DINA PERSONUPPGIFTER?</div>
              5.1 CDI lagrar aldrig uppgifter längre än nödvändigt med hänsyn till ändamålen med behandlingen. Vi genomför därför regelbundna gallringar bland lagrade personuppgifter och tar bort de uppgifter som inte längre behövs.
              5.2 CDI sparar uppgifter om dig som onlinekund eller kontoinnehavare i högst 12 månader efter det att du senast genomförde en beställning eller på annat sätt interagerat med CDI. Exempelvis raderas ditt konto hos CDI efter denna tidsperiod. CDI kan emellertid behöva lagra personuppgifterna efter denna tidsperiod, bland annat för att administrera eventuella garantier, reklamationsfrister, för att följa lagkrav samt hantera rättsliga krav som kan tänkas riktas mot CDI.

              <div className="svp-head">6 VEM HAR TILLGÅNG TILL DINA UPPGIFTER?</div>
              6.1 CDI överlämnar information till koncernbolag och samarbetspartners såsom betalningsleverantörer för hantering av din betalning samt de bud och restauranger som tillhandahåller dina beställningar. CDI kan även behöva överlämna information till relevant tredje man om vi har en rättslig skyldighet att göra såsom för att upptäcka, förebygga eller uppmärksamma bedrägerier.

              <div className="svp-head">7 VAR LAGRAR VI DINA PERSONUPPGIFTER?</div>
              CDI kan komma att behandla dina personuppgifter både inom och utanför EU/EES. CDI kommer vidta nödvändiga åtgärder för att säkerställa att överföringen sker på ett lagligt sätt och uppgifter fortsätter vara skyddade av de mottagande parterna utanför EU/EES.

              <div className="svp-head">8 COOKIES</div>
              8.1 När du besöker cdisystem.com använder vi cookies. Du hittar mer information om hur vi behandlar cookies i vår cookiepolicy.
              8.2 Notera att om du väljer att inte acceptera cookies kommer du inte kunna logga in eller genomföra några beställningar på vår webbplats.

              <div className="svp-head">9 PROFILERING</div>
              9.1 Om du är kontoinnehavare kan CDI behandla dina personuppgifter genom profilering såsom analys om hur du använder vår webbplats, om vilka tjänster och erbjudanden du varit intresserad av, uppgifter om dina inköp och information om ditt kontoinnehav för att tillhandahålla dig erbjudanden som vi tror passar dig. Du kan närsomhelst invända mot behandling av personuppgifter genom profilering. Detta gäller dock inte om sådan behandling är nödvändig för ingående eller fullgörande av avtal med dig eller om sådan behandling är tillåten enligt tillämplig lagstiftning.

              <div className="svp-head">10 VAD HAR DU SOM REGISTRERAD FÖR RÄTTIGHETER?</div>
              10.1 Rätt till tillgång. Du har rätt att vända dig till CDI i egenskap av personuppgiftsansvarig och begära tillgång till de personuppgifter som vi behandlar, samt begära information om bland annat ändamålen med behandlingen och vilka som mottagit personuppgifterna. CDI kommer i egenskap av personuppgiftsansvarig att förse dig med kostnadsfri kopia på de personuppgifter som behandlas. Vid eventuella extra kopior kan CDI komma att ta ut en administrationsavgift.
              10.2 Rätt till rättelse och begränsning. Du har rätt att utan onödigt dröjsmål få dina personuppgifter rättade eller, under vissa förutsättningar begränsade. Om du anser att CDI behandlar personuppgifter om dig som är felaktiga eller ofullständiga kan du kräva att få dessa rättade eller kompletterade.
              10.3 Rätt till radering. Du har även rätt att få dina uppgifter raderade bland annat om de inte längre är nödvändiga för ändamålet eller om behandlingen av dem baseras på samtycke och detta har återkallats. Det kan dock finnas lagkrav eller avtalsförhållande som gör att vi inte kan radera dina personuppgifter.
              10.4 Rätt att invända. Som registrerad har du rätt att när som helst invända mot behandling av dina personuppgifter om den lagliga grunden för behandlingen utgörs av intresseavvägning. Du som registrerad har även rätt att när som helst invända mot behandling av dina personuppgifter om dessa behandlas för direkt marknadsföring.
              10.5 Rätt till dataportabilitet. Som registrerad har du rätt att få ut de personuppgifter som du tillhandahållit CDI som personuppgiftsansvarig och har rätt att överföra dessa uppgifter till en annan personuppgiftsansvarig (dataportabilitet). Detta gäller dock under förutsättning att det är tekniskt möjligt och att behandlingen varit nödvändig för fullgörande av avtal.
              10.6 Rätt att lämna in klagomål. Om du är missnöjd med hur vi behandlat dina personuppgifter ber vi dig att kontakta oss, se våra kontaktuppgifter i avsnitt 12. Du har även rätt att ge in ett klagomål på vår personuppgiftsbehandling till: Datainspektionen / Integritetsskyddsmyndigheten (myndigheten byter namn till Integritetsskyddsmyndigheten under år 2018) Box 8114 104 20 Stockholm datainspektionen@datainspektionen.com

              <div className="svp-head">11 ÄNDRINGAR AV POLICYN</div>
              CDI förbehåller sig rätten att ändra och uppdatera denna policy. Vid materiella ändringar i policyn eller om befintlig information ska behandlas på annat sätt än vad som anges i policyn, kommer CDI informera om detta på lämpligt sätt.

              <div className="svp-head">12 KONTAKTA CDI</div>
              12.1 Vid frågor eller vid andra önskemål avseende personuppgifter vänligen kontakta CDI's kontaktperson avseende personuppgiftshantering:

              <div className="svp-head">Kontaktuppgifter</div>
              CDI System AB
              E-postadress: info@cdisystem.com
          </div>
      </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Privacy));
